import InputCom from "../Helpers/InputCom";
import PageTitle from "../Helpers/PageTitle";
import Layout from "../Partials/Layout";
import { useEffect, useState } from "react";
import {
  orderNow,
  quotaionStore,
  quoteionStore,
  resetOrder,
} from "../../actions/commonAction";
import { connect } from "react-redux";
import { toast } from "react-toastify";

const Reqforquote = (props) => {
  const backgroundUrl = `${process.env.PUBLIC_URL}/assets/images/RFQ-Page.jpg`;
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    mobile: "",
    company: "",
    mpn: "",
    urgency: "",
    message: "",
  });

  const [formErrorData, setFormErrorData] = useState({
    name_error: "",
    email_error: "",
    mobile_error: "",
    mpn_error: "",
  });

  const resetForm = () => {
    setFormData({
      name: "",
      email: "",
      mobile: "",
      company: "",
      mpn: "",
      urgency: "",
      message: "",
    });
    setFormErrorData({
      name_error: "",
      email_error: "",
      mobile_error: "",
      mpn_error: "",
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const quotationSubmitButton = document.getElementById("quotationSubmitButton");
    quotationSubmitButton.disabled = true;
    if (formData.email.toLowerCase().includes("mailinator")) {
      setFormErrorData({
        ...formErrorData,
        email_error: "Mailinator emails are not allowed",
      });
      quotationSubmitButton.disabled = false;
    } else {
      const data = {
        name: formData.name,
        email: formData.email,
        mobile: formData.mobile,
        company: formData.company,
        mpn: formData.mpn,
        urgency: formData.urgency,
        message: formData.message,
      };
      props.quoteionStore(data);
    }
  };

  useEffect(() => {
    if (
      props.quote_res?.message !== "" &&
      props.quote_res?.message !== undefined
    ) {
      setFormErrorData((prevData) => ({
        ...prevData,
        name_error: props.quote_res?.errors?.name
          ? props.quote_res.errors.name[0]
          : "",
        email_error: props.quote_res?.errors?.email
          ? props.quote_res.errors.email[0]
          : "",
        mobile_error: props.quote_res?.errors?.mobile
          ? props.quote_res.errors.mobile[0]
          : "",
        mpn_error: props.quote_res?.errors?.mpn
          ? props.quote_res.errors.mpn[0]
          : "",
      }));
    }

    if (props.quote_res?.status === 201) {
      resetForm();
      toast.success(`Quotation Created Successfully!`, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
    const quotationSubmitButton = document.getElementById("quotationSubmitButton");
    quotationSubmitButton.disabled = false;
  }, [props.quote_res]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "email") {
      if (value.toLowerCase().includes("mailinator")) {
        setFormErrorData((prevData) => ({
          ...prevData,
          email_error: "Mailinator emails are not allowed",
        }));
      } else {
        setFormErrorData((prevData) => ({
          ...prevData,
          email_error: "",
        }));
      }
    }
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  return (
    <Layout childrenClasses="pt-0 pb-0">
      <div className="page-title reqforquote_main">
        <PageTitle
          title="Request For Quote"
          // breadcrumb={[
          //   { name: "home", path: "/" },
          //   { name: "Request For Quote", path: "/Reqforquote" },
          // ]}
        />
      </div>
      <div
        className="contact-wrapper w-full req-wrapper p-5 req_for_quote_wrapper"
        style={{
          background: `url(${backgroundUrl})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          position: "relative",
        }}
      >
        <div className="container-x mx-auto">
          <div className="main-wrapper w-full lg:flex lg:space-x-[30px] justify-center">
            <div className="formDiv mb-3">
              <form onSubmit={handleSubmit} id={"quoteForm"}>
                <div className="mb-2">
                  <label htmlFor="name" className="form-label">
                    Name
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="name"
                    placeholder={"Name"}
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                  />
                  <span className="text-danger">
                    {formErrorData.name_error}
                  </span>
                </div>
                <div className="mb-2">
                  <label htmlFor="name" className="form-label">
                    Company
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="company"
                    placeholder={"Company"}
                    name="company"
                    value={formData.company}
                    onChange={handleChange}
                  />
                </div>
                <div className="mb-2">
                  <label htmlFor="name" className="form-label">
                    Mpn
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="mpn"
                    placeholder={"Mpn"}
                    name="mpn"
                    value={formData.mpn}
                    onChange={handleChange}
                  />
                  <span className="text-danger">{formErrorData.mpn_error}</span>
                </div>
                <div className="mb-2">
                  <label htmlFor="email" className="form-label">
                    Email
                  </label>
                  <input
                    type="email"
                    className="form-control"
                    id="email"
                    placeholder={"Email"}
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                  />
                  <span className="text-danger">
                    {formErrorData.email_error}
                  </span>
                </div>
                <div className="mb-2">
                  <label htmlFor="phone" className="form-label">
                    Phone
                  </label>
                  <input
                    type="tel"
                    className="form-control"
                    id="mobile"
                    placeholder={"Mobile"}
                    name="mobile"
                    value={formData.mobile}
                    onChange={handleChange}
                  />
                  <span className="text-danger">
                    {formErrorData.mobile_error}
                  </span>
                </div>
                <div className="mb-2">
                  <label htmlFor="urgency" className="form-label">
                    How soon do you need it?
                  </label>
                  <select
                    onChange={handleChange}
                    className="form-select"
                    id="urgency"
                    name="urgency"
                  >
                    <option value="">Select an option</option>
                    <option value="today">Today</option>
                    <option value="thisWeek">This Week</option>
                    <option value="30DaysPlus">30 Days+</option>
                  </select>
                </div>
                <div className="mb-2">
                  <label htmlFor="name" className="form-label">
                    Message
                  </label>
                  <textarea
                    className="form-control"
                    id="message"
                    placeholder="Message"
                    value={formData.message}
                    name="message"
                    onChange={handleChange}
                  ></textarea>
                </div>
                <button
                  type="submit"
                  id="quotationSubmitButton"
                  className="font-700 text-sm font-semibold  btnSubmit w-full h-full bg-qyellow btnYellow reqforquote_btn_submit_main"
                >
                  Send Quote
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};
const mapStateToProps = (state) => ({
  quote_res: state.common.quoteRes,
});

const mapDispatchToProps = (dispatch) => ({
  quoteionStore: (jsondata) => dispatch(quoteionStore(jsondata)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Reqforquote);
