import {homeConstants, productConstants} from "../../constants";

const initialState = {
    testRes: {
        data: [],
        links: {},
        meta: {},
        loading: true,
    },
    mainCategoryTreeviewRes:{
        data:[]
    },
    bannerRes: {
        data: [],
        links: {},
        meta: {},
        loading: true,
    },
    brandsRes: {
        data: [],
        links: {},
        meta: {},
        loading: true,
    },
    navbarRes: [],
    navbarDetailRes: {
        data: {},
    },
    mainCategoryDetailRes: {
        data: {},
    },
    mainManufacturerDetailRes: {
        data: {},
    },
    manufactureListingRes: {
        data: [],
        links: {},
        meta: {},
        loading: true,
    },
    topManufacturers: {
        data: [],
        links: {},
        meta: {},
        loading: true,
    },
    topCategories: {
        data: [],
        links: {},
        meta: {},
        loading: true,
    },
    featuredcategoriesRes: {
        data: [],
    },

    // Add other initial state properties as needed
};

const homeReducer = (state = initialState, action) => {
    switch (action.type) {
        case homeConstants.TEST:
            return {
                ...state,
                testRes: action.payload.data,
                loading: false,
            };
        case homeConstants.TEST_ERROR:
            return {
                ...state,
                // Handle error state as needed
            };
        case homeConstants.MAIN_CATEGORY_TREE:
            return {
                ...state,
                mainCategoryTreeviewRes: action.payload.data,
                loading: false,
            };
        case homeConstants.MAIN_CATEGORY_TREE_ERROR:
            return {
                ...state,
                // Handle error state as needed
            };
        case homeConstants.BANNER:
            return {
                ...state,
                bannerRes: action.payload.data,
                loading: false,
            };
        case homeConstants.BANNER_ERROR:
            return {
                ...state,
                // Handle error state as needed
            };
        case homeConstants.FEATURE_CATEGORIES:
            return {
                ...state,
                featuredcategoriesRes: action.payload.data,
                loading: false,
            };
        case homeConstants.FEATURE_CATEGORIES_ERROR:
            return {
                ...state,
            };
        case homeConstants.TOP_MANUFACTURE:
            return {
                ...state,
                topManufacturers: action.payload.data,
                loading: false,
            };
        case homeConstants.TOP_MANUFACTURE_ERROR:
            return {
                ...state,
                // Handle error state as needed
            };
        case homeConstants.TOP_CATEGORY:
            return {
                ...state,
                topCategories: action.payload.data,
                loading: false,
            };
        case homeConstants.TOP_CATEGORY_ERROR:
            return {
                ...state,
                // Handle error state as needed
            };
        case homeConstants.BRANDS:
            return {
                ...state,
                brandsRes: action.payload.data,
                loading: false,
            };
        case homeConstants.BRANDS_ERROR:
            return {
                ...state,
                // Handle error state as needed
            };
        case homeConstants.NAVBAR_HEADER:
            return {
                ...state,
                navbarRes: action.payload.data,
                loading: false,
            };
        case homeConstants.NAVBAR_HEADER_ERROR:
            return {
                ...state,
                // Handle error state as needed
            };
        case homeConstants.NAVBAR_HEADER_DETAIL:
            return {
                ...state,
                navbarDetailRes: action.payload.data,
                loading: false,
            };
        case homeConstants.NAVBAR_HEADER_DETAIL_ERROR:
            return {
                ...state,
                navbarDetailRes: action.payload.data,
                loading: false,
            };
        case homeConstants.MAIN_CATEGORY_DETAIL_HEADER:
            return {
                ...state,
                mainCategoryDetailRes: action.payload.data,
                loading: false,
            };
        case homeConstants.MAIN_CATEGORY_DETAIL_HEADER_ERROR:
            return {
                ...state,
                mainCategoryDetailRes: action.payload,
                loading: false,
            };
        case homeConstants.MAIN_MANUFACTURES_DETAIL_HEADER:
            return {
                ...state,
                mainManufacturerDetailRes: action.payload.data,
                loading: false,
            };
        case homeConstants.MAIN_MANUFACTURES_DETAIL_HEADER_ERROR:
            return {
                ...state,
                // Handle error state as needed
            };
        case homeConstants.MANUFACTURE_LISTING:
            return {
                ...state,
                manufactureListingRes: action.payload.data,
                loading: false,
            };
        case homeConstants.MANUFACTURE_LISTING_ERROR:
            return {
                ...state,
                // Handle error state as needed
            };
        // Add other cases as needed
        default:
            return state;
    }
};

export default homeReducer;
