import blog from "../../data/blogs.json";
import BlogCard from "../Helpers/Cards/BlogCard";
import DataIteration from "../Helpers/DataIteration";
import PageTitle from "../Helpers/PageTitle";
import Layout from "../Partials/Layout";
import { connect } from "react-redux";
import { blogs } from "../../actions/commonAction";
import React, { useEffect, useState } from "react";
import PaginationComponent from "../Pagination/PaginationComponent";
import Loader from "../loader";
import ProductCardStyleOriginal from "../Helpers/Cards/ProductCardStyleOriginal";
import {Helmet} from "react-helmet-async";
import {Link} from "react-router-dom";

const Blogs = (props) => {

  const [blogs, setBlogs] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalProducts, setTotalProducts] = useState(1);
  const [loading, setLoading] = useState(true);

  const [searchQuery, setSearchQuery] = useState('');

  // Function to handle input changes
  const handleInputChange = (e) => {
    setSearchQuery(e.target.value);
  };

  // Function to handle search button click
  const handleSearchClick = () => {
    props.blogs(currentPage,searchQuery);
  };

  const handlePageChange = (newPage) => {
    setLoading(true);
    setCurrentPage(newPage);
  };


  useEffect(() => {
    props.blogs(currentPage);
  }, [currentPage])

  useEffect(() => {
    if (props.blogs_res.data && props.blogs_res.data.length > 0) {
      setLoading(false);
      setTotalProducts(props.blogs_res.meta.total);
      setBlogs(props.blogs_res.data)
    }else {
      setLoading(false);
      setTotalProducts(props.blogs_res.meta.total);
      setBlogs(props.blogs_res.data)
    }
    if (props.blogs_res && props.blogs_res.links) {
      setTotalPages(props.blogs_res.meta.last_page);
    }
  }, [props.blogs_res]);

  return (
      <>
        <Helmet >
          <title>ETechBuy Blogs | Your Trusted Source for Tech Knowledge</title>
          <meta name="title" content="ETechBuy Blogs | Your Trusted Source for Tech Knowledge" />
          <meta name="description" content="Get tech smarter with Etechbuy's blog! Discover news, analysis, and expert advice to navigate the ever-changing world of technology" />
        </Helmet>

        <Layout childrenClasses="pt-0 pb-0">
          <div className="blogs-wrapper w-full-width">
            <div className="title-bar">
              <PageTitle
                  title="ETechBuy Blogs"
                  // breadcrumb={[
                  //   { name: "home", path: "/" },
                  //   { name: "blogs", path: "/blogs" },
                  // ]}
              />
            </div>
          </div>
          <div className="w-full py-[60px]">
            <div className="container-x mx-auto">
              <div className="w-full">
                <div>
                  <p className="font-400 text-[13px]">
                    <div className={`w-full h-full flex items-center border border-qgray-border bg-white`}>
                      <div className="flex-1 h-full">
                        <form
                            action="#"
                            className="h-full"
                            onSubmit={(e) => {
                              e.preventDefault();
                              handleSearchClick(); // Trigger search on Enter key press
                            }}
                        >
                          <input
                              type="text"
                              className="form-control search-input"
                              placeholder="Search Blog..."
                              value={searchQuery}
                              id="blog-search-bar"
                              onChange={handleInputChange}
                          />
                        </form>
                      </div>
                      <div className="w-[1px] h-[22px] bg-qgray-border"></div>
                      <div>
                        <button
                            className={`w-[93px] h-full text-sm font-600 btnSearchs search-btn`}
                            type="button"
                            id="blog-search-button"
                            onClick={handleSearchClick} // Trigger search on button click
                        >
                          Search
                        </button>
                      </div>
                    </div>
                  </p>
                  <p className="font-400 text-[13px]">
                    <PaginationComponent
                        currentPage={currentPage}
                        totalPages={totalPages}
                        onPageChange={handlePageChange}
                        pageLimit={99999}
                    />
                  </p>
                  <div>
                    <p className="font-400 text-[13px]">
                      <span className="text-qgray"> Showing</span> 1–{6} of {totalProducts} Results
                    </p>
                  </div>
                </div>
                <div className="blogBox row ">

                  {loading ? (
                      <Loader></Loader>
                  ) : (
                      <DataIteration
                          datas={blogs}
                          startLength={0}
                          endLength={blogs.length}
                      >
                        {({ datas }) => (
                            <div
                                key={datas.id}
                                className="item col-lg-6 mb-4"
                            >
                              <BlogCard datas={datas} />
                            </div>
                        )}
                      </DataIteration>
                  )}

                </div>
              </div>
            </div>
          </div>
        </Layout>
      </>

  );
}

const mapStateToProps = (state) => {
  return {
    blogs_res: state.common.blogsRes,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    blogs: (page,title) => dispatch(blogs(page,title,6)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Blogs);
