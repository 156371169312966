import React, { forwardRef, useState, useRef, Children, cloneElement } from "react";
import styled from "@emotion/styled";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import NestedMenuItem from "./NestedMenuItem";

const Dropdown = forwardRef(
    (
        {
          trigger,
          menu,
          isLength,
          setdataLength,
          allLength,
          keepOpen: keepOpenGlobal,
          isOpen: controlledIsOpen,
          onOpen: onControlledOpen,
          minWidth,
        },
        ref
    ) => {
      const [isInternalOpen, setInternalOpen] = useState(null);

      const isOpen = controlledIsOpen || isInternalOpen;

      let anchorRef = useRef(null);
      if (ref) {
        anchorRef = ref;
      }

      const handleOpen = (event) => {
        event.stopPropagation();

        if (menu.length) {
          onControlledOpen
              ? onControlledOpen(event.currentTarget)
              : setInternalOpen(event.currentTarget);
        }
      };

      const handleClose = (event) => {
        event.stopPropagation();

        if (anchorRef.current && anchorRef.current.contains(event.target)) {
          return;
        }

        handleForceClose();
      };

      const handleForceClose = () => {
        onControlledOpen ? onControlledOpen(null) : setInternalOpen(null);
      };

      const renderMenu = (menuItem, index) => {
        if (!menuItem) {
          return null; // Skip rendering if menuItem is null
        }

        const { keepOpen: keepOpenLocal, ...props } = menuItem.props;

        let extraProps = {};
        if (props.menu) {
          extraProps = {
            parentMenuOpen: isOpen,
          };
        }

        return React.createElement(menuItem.type, {
          ...props,
          key: index,
          ...extraProps,
          onClick: (event) => {
            event.stopPropagation();

            if (!keepOpenGlobal && !keepOpenLocal) {
              handleClose(event);
            }

            if (menuItem.props.onClick) {
              menuItem.props.onClick(event);
            }
          },
          children: props.menu
              ? Children.map(props.menu, renderMenu)
              : props.children,
        });
      };

      return (
          <div>
            {cloneElement(trigger, {
              onClick: isOpen ? handleForceClose : handleOpen,
              ref: anchorRef,
            })}

            <Menu
                style={{ marginLeft: -17, top: 10 }}
                PaperProps={{ sx: { minWidth: minWidth ?? 0 } }}
                anchorEl={isOpen}
                open={!!isOpen}
                onClose={handleClose}
            >
              {Children.map(menu, renderMenu)}
              {isLength !== allLength?.[0]?.children?.length && (
                  <p style={{ textDecoration:"underline",textAlign:"center",cursor:"pointer" }} onClick={() => setdataLength(allLength?.[0]?.children?.length)}>
                    See More
                  </p>
              )}
            </Menu>
          </div>
      );
    }
);

const DropdownMenuItem = styled(MenuItem)`
  display: flex;
  justify-content: space-between !important;

  & > svg {
    margin-left: 32px;
  }
`;

const DropdownNestedMenuItem = styled(NestedMenuItem)`
  display: flex;
  justify-content: space-between !important;

  & > svg {
    margin-left: 62px;
  }
`;

export { Dropdown, DropdownMenuItem, DropdownNestedMenuItem };
