import { useLocation, Link } from 'react-router-dom';

export default function BreadcrumbComProduct(props) {
  const { className, paths ,title} = props;

  // // Ensure that pathname is defined before using it
  // const pathname = path ? String(path.url) : '';
  //
  // // Function to remove HTML tags from a string
  // const removeHtmlTags = (str) => str.replace(/<\/?[^>]+(>|$)/g, '');
  //
  // // Function to remove hyphens from a string
  // const removeHyphens = (str) => str.replace(/-/g, ' ');
  //
  // // Function to remove ".html" from the end of a string for display
  const removeHtmlExtension = (str) => str.replace(/\.html$/, '');
  //
  // // Split the pathname into segments
  // const segments = pathname.split('/').filter((segment) => segment !== '');
  //
  // // Create an array of paths based on the segments
  // const paths = segments.map((segment, index) => ({
  //   name: removeHtmlExtension(removeHyphens(removeHtmlTags(segment))),
  //   path: `/${segments.slice(0, index + 1).join('/')}`,
  // }));

  // paths.unshift({
  //   name: 'Home',
  //   path: '/',
  // });
  return (
      <>
          {paths && paths.length > 0 && (
              <div className="breadcrumb-wrapper font-400 text-[13px] text-qblack mb-[23px]">
                  {paths.map((path, index) => (
                      <span key={path.name}>
        <Link to={index === 0 ? '/' : `/category${path.path}`}>
          <span className="mx-1 capitalize hover:text-qyellow hover:border-qyellow">
            {removeHtmlExtension(path.name.toUpperCase())}
          </span>
        </Link>
                          {index < paths.length - 1 && <span className="separator">/</span>}
      </span>
                  ))}
                  / <strong> {title && title.toUpperCase()} </strong>
              </div>
          )}

      </>
  );
}
