import Layout from "../Partials/Layout";
import { connect } from "react-redux";
import React, { useEffect, useState } from "react";
import {
  getMaincategoryTreeview,
  getHomeNavbar,
} from "../../actions/homeAction";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

const Sitemap = (props) => {
  const [categoriesData, setCategoriesData] = useState(null);
  const [websiteHeaderMenuData, setWebsiteHeaderMenuData] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      try {
        await props.getMaincategoryTreeview();
      } catch (error) {
        console.error("Error fetching data:", error);
      }
      try {
        await props.getHomeNavbar();
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (props?.websiteCategoryTreeView?.[0]?.children) {
      setCategoriesData(props.websiteCategoryTreeView[0].children);
    }
    if (props?.websiteHeaderMenuTreeview?.length > 0) {
      const titles = [
        "MEMORY",
        "PROCESSORS",
        "HARD DRIVES",
        "MOTHERBOARDS",
        "NETWORK & ACCESSORIES",
        "POWER SUPPLIES",
      ];

      const websiteHeaderMenuData = titles.reduce((acc, title) => {
        const key = title.toLowerCase().replace(/ & /g, "").replace(/\s+/g, "");
        acc[key] = props.websiteHeaderMenuTreeview.find(
          (item) => item.title === title
        );
        return acc;
      }, {});

      setWebsiteHeaderMenuData(websiteHeaderMenuData);
    }
  }, [props.websiteCategoryTreeView, props.websiteHeaderMenuTreeview]);

  const FormatData = ({ data, level = 0, urlPrefix }) => {
    const isTopLevel = level === 0;

    return (
      <ul
        style={{
          paddingLeft: level > 0 ? "25px" : "0",
          gap: isTopLevel ? "20px" : "0",
        }}
        className={`sitemap-category-wrapper ${isTopLevel ? "webkitColumns" : ""
          }`}
      >
        {data.map((category) => (
          <li
            key={category.id}
            style={{
              display: isTopLevel ? "inline-block" : "flex",
              flexDirection: "column",
              width: "100%",
              borderBottom: level === 0 ? "1px solid #e0e0e0" : "none",
              padding: isTopLevel ? "20px" : "0px",
              backgroundColor: isTopLevel ? "#eee" : "transparent",
              border: isTopLevel ? "1px solid #d9d9d9" : "none",
              marginBottom: isTopLevel ? "20px" : "0px",
              borderLeft:
                level === 1
                  ? "1px solid #333"
                  : level === 0
                    ? "1px solid #d9d9d9"
                    : level < 2
                      ? "none"
                      : "1px solid #333",
            }}
          >
            <Link to={urlPrefix + "/" + category.url}>
              <span
                style={{
                  fontSize: level === 0 ? "25px" : "14px",
                  color: "#333",
                  paddingLeft: 15,
                  paddingBottom: level === 0 ? "20px" : "0px",
                  display: "block",
                  fontWeight: level === 1 ? "700" : "400",
                  lineHeight: "28px",
                  cursor: "pointer",
                }}
              >
                {category.title}{" "}
                {level === 0 ? (
                  <ArrowForwardIosIcon
                    style={{
                      fontSize: level === 0 ? "20px" : "12px",
                      color: "#333",
                      marginRight: "0px",
                    }}
                  />
                ) : level === 1 ? (
                  <ArrowForwardIosIcon
                    style={{
                      fontSize: level === 0 ? "26px" : "12px",
                      color: "#333",
                      marginRight: "0px",
                    }}
                  />
                ) : null}
              </span>
            </Link>
            {category.children && category.children.length > 0 && (
              <FormatData data={category.children} urlPrefix={urlPrefix} level={level + 1} />
            )}
          </li>
        ))}
      </ul>
    );
  };

  return (
    <>
      <Helmet>
        <title>ETechBuy Sitemap | Your Trusted Source for Tech Knowledge</title>
        <meta
          name="title"
          content="ETechBuy Sitemap | Your Trusted Source for Tech Knowledge"
        />
        <meta
          name="description"
          content="Get tech smarter with Etechbuy's Sitemap! Discover news, analysis, and expert advice to navigate the ever-changing world of technology"
        />
      </Helmet>

      <Layout childrenClasses="pt-0 pb-0 ">
        <div className="sitemap-main-wrapper container-x mx-auto">
          {categoriesData && (
            <>
              <span className="fs-3 sitemap_main_heading">
                Category Sitemap
              </span>
              <FormatData data={categoriesData} urlPrefix="/category" />
            </>
          )}
          {websiteHeaderMenuData?.memory && (
            <>
              <span className="fs-3 sitemap_main_heading">Memory Filters</span>
              <FormatData data={websiteHeaderMenuData.memory.children} urlPrefix="/categories" />
            </>
          )}
          {websiteHeaderMenuData?.processors && (
            <>
              <span className="fs-3 sitemap_main_heading">
                Processors Filters
              </span>
              <FormatData data={websiteHeaderMenuData.processors.children} urlPrefix="/categories" />
            </>
          )}
          {websiteHeaderMenuData?.harddrives && (
            <>
              <span className="fs-3 sitemap_main_heading">
                Hard Drives Filters
              </span>
              <FormatData data={websiteHeaderMenuData.harddrives.children} urlPrefix="/categories" />
            </>
          )}
          {websiteHeaderMenuData?.motherboards && (
            <>
              <span className="fs-3 sitemap_main_heading">
                Motherboards Filters
              </span>
              <FormatData data={websiteHeaderMenuData.motherboards.children} urlPrefix="/categories" />
            </>
          )}
          {websiteHeaderMenuData?.networkaccessories && (
            <>
              <span className="fs-3 sitemap_main_heading">
                Networking Filters
              </span>
              <FormatData
                data={websiteHeaderMenuData.networkaccessories.children} urlPrefix="/categories"
              />
            </>
          )}
          {websiteHeaderMenuData?.powersupplies && (
            <>
              <span className="fs-3 sitemap_main_heading">
                Power Supplies Filters
              </span>
              <FormatData data={websiteHeaderMenuData.powersupplies.children} urlPrefix="/categories" />
            </>
          )}
        </div>
      </Layout>
    </>
  );
};

const mapStateToProps = (state) => ({
  websiteCategoryTreeView: state.home.mainCategoryTreeviewRes,
  websiteHeaderMenuTreeview: state.home.navbarRes,
});

const mapDispatchToProps = (dispatch) => ({
  getMaincategoryTreeview: () => dispatch(getMaincategoryTreeview()),
  getHomeNavbar: () => dispatch(getHomeNavbar()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Sitemap);