import { configureStore } from '@reduxjs/toolkit';
import rootReducer from '../store/reducers/rootReducer';
import thunk from 'redux-thunk';

const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
        }).concat(thunk),
    devTools: process.env.NODE_ENV !== 'production',
});


export default store;





