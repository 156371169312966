import React, { useState } from "react";
import "./ClientReviewCard.css";
import ClientReviewModal from "../ClientReviewModal/ClientReviewModal";
import ClientReviewLearnMoreModal from "../ClientReviewModal/ClientReviewLearnMoreModal";
import { FaCircleCheck } from "react-icons/fa6";
import { IoIosStar } from "react-icons/io";
import ImageWithFallback from "../ImageWithFallback";

const ClientReviewCard = (props) => {
  const { datas } = props;
  const [showReviewModal, setShowReviewModal] = useState(false);
  const [showLearnMoreModal, setShowLearnMoreModal] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [learnMoreData, setLearnMoreData] = useState(null);
  const [isHovered, setIsHovered] = useState(false);

  const ReviewModalOpen = (productData) => {
    setSelectedProduct(productData);
    setShowReviewModal(true);
  };

  const ReviewModalClose = () => {
    setShowReviewModal(false);
    setLearnMoreData(null); // Clear learn more data when closing review modal
  };

  const handleToggleInfo = () => {
    setSelectedProduct((prev) => ({ ...prev, toggleInfo: !prev?.toggleInfo }));
  };

  const handleOpenLearnMoreModal = () => {
    setLearnMoreData();
    setShowReviewModal(false); // Close review modal
    setShowLearnMoreModal(true); // Open learn more modal
  };

  const handleCloseLearnMoreModal = () => {
    setShowLearnMoreModal(false);
  };

  return (
      <>
        <div
            className="review-card-one"
            onClick={() => ReviewModalOpen(datas)}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            style={{ cursor: 'pointer' }}
        >
          <div className="review_image">
            <ImageWithFallback
                src={datas.thumbnailImage}
                fallbackSrc={datas.image}
                alt={datas.title}
                loading="lazy"
            />
          </div>
          <div className="title">
            <p className="first">{datas.customer_name}</p>
            <FaCircleCheck />
            <p className="second">Verified</p>
          </div>
          <div className="date">
            <span>{datas.review_date}</span>
          </div>
          <div className="star">
            {Array.from({ length: datas.product_rating }).map((_, index) => (
                <IoIosStar key={index} />
            ))}
          </div>
          <div className="discription">
            <p>
              {isHovered ? datas.remarks : `${datas.remarks.substring(0, 50)}...`}
            </p>
          </div>
        </div>
        <ClientReviewModal
            open={showReviewModal}
            ReviewModalClose={ReviewModalClose} // Ensure this matches the prop name expected
            toggleInfo={selectedProduct?.toggleInfo || false}
            handleToggleInfo={handleToggleInfo}
            productData={selectedProduct}
            openLearnMoreModal={handleOpenLearnMoreModal} // Ensure this function is passed
        />
        <ClientReviewLearnMoreModal
            open={showLearnMoreModal}
            onClose={handleCloseLearnMoreModal}
            learnMoreData={learnMoreData}
        />
      </>
  );
};

export default ClientReviewCard;
