import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import PageTitle from "../Helpers/PageTitle";
import Layout from "../Partials/Layout";
import {orderUpdate, resetOrder} from "../../actions/commonAction";
import { connect } from "react-redux";
// import {PaymentElement,useElements, useStripe} from "@stripe/react-stripe-js";
// import {loadStripe} from "@stripe/stripe-js";
import {useShoppingCart} from "use-shopping-cart";
// const stripeSk = "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";
const stripeSk = "";

const Thankyou = (props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [documentId, setDocumentId] = useState("");
  const [orderData, setOrderData] = useState(null);
  const { cartDetails, clearCart, removeItem, cartCount, totalPrice } = useShoppingCart();


  // useEffect(() => {
  //   props.resetOrder();
  //
  //   if(props.order_res?.status === 201){
  //   }else{
  //     navigate('/')
  //   }
  // }, [props.resetOrder, props.order_res]);

  // useEffect(() => {
  //     console.log(props.order_update_res,'okokokok')
  // }, [props.order_update_res]);

  useEffect(() => {
    const script = document.createElement('script');
    script.async = true;
    script.src = `https://www.googletagmanager.com/gtag/js?id=G-1J8TT9GY4Q`;
    document.head.appendChild(script);

    const inlineScript = document.createElement('script');
    inlineScript.innerHTML = `
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());

      gtag('config', 'G-1J8TT9GY4Q');
    `;
    document.head.appendChild(inlineScript);

    return () => {
      document.head.removeChild(script);
      document.head.removeChild(inlineScript);
    };
  }, []);

  useEffect(()=>{

    const urlParams = new URLSearchParams(window.location.search);
    const document_id = urlParams.get("document_id");
    if(document_id){
      setDocumentId(document_id)
    }else if(location.state?.orderData) {
      const user_data = JSON.parse(location.state?.orderData);
      if(user_data?.order?.data?.data?.document_id !== null){
        setDocumentId(user_data?.order?.data?.data?.document_id);
      }else if(location.state?.document_id !== null){
        setDocumentId(location.state?.document_id);
      }
    }
    if (location.state?.orderData) {
      const user_data = JSON.parse(location.state?.orderData);
      if(user_data?.order?.data?.data){
        setOrderData(user_data.order.data.data);
      }
    }
    const payment_intent = urlParams.get("payment_intent");
    const order_id = urlParams.get("order_id");

    const fetchPaymentIntent = async (payment_intent,order_id) => {

      const requestOptions = {
        method: 'GET',
        headers: {
          Authorization: 'Bearer '+stripeSk
        },
        redirect: 'follow'
      };

      try {
        const response = await fetch("https://api.stripe.com/v1/payment_intents/"+payment_intent, requestOptions);
        const result = await response.json();
        const { status, amount_received } = result;

        if(status === "succeeded"){
          const data = {
            payment_response : result,
            paid_amount : amount_received/100,
            payment_status : true
          }
          props.orderUpdate(order_id,data)
        }else{
          const data = {
            payment_response : result,
            paid_amount : 0,
            payment_status : false
          }
          props.orderUpdate(order_id,data)
        }
        clearCart();
      } catch (error) {
        console.log('error', error);
      }
    };

    if(payment_intent !== null && order_id !== null){
      fetchPaymentIntent(payment_intent,order_id);
    }

  },[]);

  const callTagEvent = async() => {

    let clientid = null;
    let sessionid = null;

    function getClientId() {
      return new Promise((resolve) => {
        gtag('get', 'G-1J8TT9GY4Q', 'client_id', (clientID) => {
          clientid = clientID;
          resolve(clientid);
        });
      });
    }

    function getSessionId() {
      return new Promise((resolve) => {
        gtag('get', 'G-1J8TT9GY4Q', 'session_id', (sessionID) => {
          sessionid = sessionID;
          resolve(sessionid);
        });
      });
    }

    await Promise.all([getClientId(), getSessionId()]);

    const tempItems = orderData.website_order_products.map((product, _index) => ({
      item_id: product.website_product.id,
      item_name: product.website_product.short_title || product.website_product.title,
      currency: "USD",
      item_brand: product.website_product.website_manufacturer.title,
      item_category: product.website_product.website_category.title,
      price: product.sell_price,
      quantity: product.qty,
    }));

    const data = {
      client_id: clientid,
      non_personalized_ads: false,
      events: [
        {
          name: "purchase",
          params: {
            items: tempItems,
            currency: "USD",
            transaction_id: orderData?.document_id,
            shipping: orderData?.shipping_amount,
            tax: orderData?.tax_amount,
            value: orderData?.net_amount,
            session_id: sessionid,
            debug_mode: 1
          }
        }
      ]
    };

    fetch(`https://www.google-analytics.com/mp/collect?measurement_id=G-1J8TT9GY4Q&api_secret=HW3wqM1PS5m1AcwYr2BOsw`, {
      method: "POST",
      body: JSON.stringify(data)
    });
    
  }

  useEffect(() => {
    if (orderData) {
      const tempItems = orderData.website_order_products.map((product, _index) => ({
        item_id: product.website_product.id,
        item_name: product.website_product.short_title || product.website_product.title,
        currency: "USD",
        item_category: product.website_product.website_category.title,
        price: product.sell_price,
        quantity: product.qty,
      }));
      gtag("event", "purchase", {
        transaction_id: orderData?.document_id,
        value: orderData?.net_amount,
        tax: orderData?.tax_amount,
        shipping: orderData?.shipping_amount,
        currency: "USD",
        items: tempItems,
      });

      callTagEvent();

      window.uetq = window.uetq || [];
      window.uetq.push(
        'event',
        'purchase',
        {
          "revenue_value":orderData?.net_amount,
          "currency":"USD"
        }
      );

      window.sa_values = {
        site: 39536,
        token: 'f5ayB7nT',
        orderid: orderData?.document_id,
        name: orderData?.website_customer_shipping_firstname + " " + orderData?.website_customer_shipping_lastname,
        email: orderData?.website_customer_shipping_email,
        state: orderData?.website_customer_shipping_state,
      };
      
      const saLoadScript = (src) => {
        const js = document.createElement('script');
        js.src = src;
        js.type = 'text/javascript';
        document.getElementsByTagName('head')[0].appendChild(js);
      };
      saLoadScript('https://www.shopperapproved.com/thankyou/rate/39536.js');
    }
  }, [orderData]);

  return (
    <Layout childrenClasses="pt-0 pb-0">
      <div className="tracking-page-wrapper w-full">
        {/* <div className="page-title mb-[40px]">
            <PageTitle
                title="Thank you"
                breadcrumb={[
                  { name: 'home', path: '/' },
                  { name: 'Thank you', path: '/thank-you' },
                ]}
            />
          </div> */}
        <div className="content-wrapper w-full mb-[40px]">
          <div className="container-x mx-auto">
            {/* <h1 className="text-[22px] text-qblack font-semibold leading-9">
                Thank you for shopping with us.
              </h1>
              <p className="text-[15px] text-qgraytwo leading-8 mb-5">
                we've successfully received your order and sent you a confirmation email.
                <br></br>
                Your order reference number is: {location.state?.orderData?.order?.data?.document_id}
              </p>
              <div className="w-full bg-white lg:px-[30px] px-5 py-[23px] lg:flex items-center">
                <a href="/">
                  <div className="w-[220px] h-[50px] bg-[#F6F6F6] flex justify-center items-center">
                    <span className="text-sm font-semibold">Continue Shopping</span>
                  </div>
                </a>
              </div> */}
            <div className="orderBox">
              <h1 className="text-[24px] text-qblack font-semibold leading-9">
                Thank you for your purchase
              </h1>
              <img
              className="my-3"
                width="60"
                height="60"
                src={`${process.env.PUBLIC_URL}/assets/images/thankstick.png`}
                alt="thankyou"
              />
              {/* <h4 className="text-[22px] text-qblack leading-9 font-semibold">Thank you for your purchase</h4> */}
              <p className="text-[17px] text-qblack leading-9 font-semibold">
                Your Order ID is: {documentId}
                {/*{location.state?.orderData?.order?.data?.document_id}*/}
              </p>
              {/* <h6 className="text-[16px] text-qblack leading-9 font-semibold">
                You will receive an order confirmation email with your order
                details.
              </h6> */}
             
                <a href="/">
                  <div className="w-[220px] h-[50px] bg-[#08456B] flex justify-center items-center">
                    <span className="text-sm text-white font-semibold">
                      Continue Shopping
                    </span>
                  </div>
                </a>
             
            </div>
            {orderData && 
              <div className="orderDetails">
                <table className="table table-bordered">
                  <thead>
                    <tr>
                      <th className="text-white" style={{ backgroundColor: "#08456B"}} scope="col">#</th>
                      <th className="text-white" style={{ backgroundColor: "#08456B"}} scope="col">Item Description</th>
                      <th className="text-white" style={{ backgroundColor: "#08456B"}} scope="col">Price</th>
                      <th className="text-white" style={{ backgroundColor: "#08456B"}} scope="col">Qty</th>
                      <th className="text-white" style={{ backgroundColor: "#08456B"}} scope="col">Sub Total</th>
                    </tr>
                  </thead>
                  <tbody>
                    {orderData.website_order_products.length > 0 &&
                    orderData.website_order_products.map((product, index) => (
                      <tr key={"websiteorderproduct" + product.id}>
                        <th scope="row">{index + 1}</th>
                        <td>{product.website_product.short_title || product.website_product.title}</td>
                        <td className="text-end">${product.sell_price}</td>
                        <td className="text-end">{product.qty}</td>
                        <td className="text-end">${product.net_amount}</td>
                      </tr>
                      ))
                    }
                    <tr>
                      <td className="text-end" colSpan={4}>Total</td>
                      <td className="text-end">${orderData.gross_amount}</td>
                    </tr>
                    <tr>
                      <td className="text-end" colSpan={4}>Shipping</td>
                      <td className="text-end">${orderData.shipping_amount}</td>
                    </tr>
                    <tr>
                      <td className="text-end" colSpan={4}>Tax</td>
                      <td className="text-end">${orderData.tax_amount}</td>
                    </tr>
                    <tr>
                      <td className="text-end" colSpan={4}>Net Total</td>
                      <td className="text-end">${orderData.net_amount}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            }
          </div>
        </div>
      </div>
    </Layout>
  );
};

const mapStateToProps = (state) => ({
  order_res: state.common.orderRes,
  order_update_res: state.common.orderUpdateRes,
});
const mapDispatchToProps = (dispatch) => ({
  resetOrder: () => dispatch(resetOrder()),
  orderUpdate: (id,data) => dispatch(orderUpdate(id,data)),
});

export default connect(null, mapDispatchToProps)(Thankyou);
