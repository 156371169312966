import React, { useState, useEffect } from 'react';
import "../tabs/tabs.css";
import {getCustomerDetail, getCustomerOrders, postReview, postReviewReset} from "../../../../actions/customerAction";
import {connect} from "react-redux";
import {useLocation, useNavigate} from 'react-router-dom';
import Modal from './ReviewImageModel';
import ReviewImageModel from "./ReviewImageModel"; // Adjust the path as per your project structure
import { Link } from "react-router-dom";

const ReviewTab = (props) => {

    const [productReviews, setProductReviews] = useState([]);

    const location = useLocation();
    const navigate = useNavigate();

    const [showModal, setShowModal] = useState(false);
    const [currentImage, setCurrentImage] = useState('');

    const handleCloseModal = () => setShowModal(false);
    const handleOpenModal = (imageUrl) => {
        setCurrentImage(imageUrl);
        setShowModal(true);
    };

    useEffect(() => {
        props.getCustomerDetail();
    }, []);


    useEffect(() => {
        if(props.customer_review_res?.data?.website_order_product_reviews !== undefined){
            setProductReviews(props.customer_review_res?.data?.website_order_product_reviews)
        }
    }, [props.customer_review_res]);

    const handleResubmitClick = (resubmitData) => {
        navigate('/profile#FeedBackForm', { state: { resubmitData } });
    };
    return (
        <>
            <ReviewImageModel show={showModal} onClose={handleCloseModal}>
                <img src={currentImage} alt="popup" />
            </ReviewImageModel>
            <div className='profileTab'>
                <div className='reviews'>
                    <h2 className='font-bold text-[24px] text-qblack mb-2'>My Reviews</h2>
                </div>
                {productReviews.map((item, index) => (
                    <div>
                        <div className='reviews mt-4'>
                            <div className='reviewsTab'>
                                <Link to={`/${item?.website_order_product?.website_product?.url || ''}`}>
                                    <div className='reviewBox'>
                                        {item.website_order_product?.website_product?.product?.images[0]?.original_url && (
                                            <img
                                                src={item.website_order_product.website_product.product.images[0].original_url}
                                                alt="payment"
                                                width="150px"
                                                height="100px"
                                            />
                                        )}
                                        <div className='pl-3'>
                                            <h6>{item?.website_order_product?.website_product?.title}</h6>
                                            <p>{item?.website_order_product?.website_product?.short_title}</p>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                            <div className='reviewsTab'>
                                <div className='rating'>
                                    <h6 className="text-center">Product Rating</h6>
                                    <StarChooser stars={item?.product_rating} />
                                    <Commender rate={item?.product_rating} />
                                </div>
                            </div>
                            <div className='reviewsTab ratingsReviews'>
                                <div className='rating'>
                                    <h6 className="text-center">Seller Service</h6>
                                    <StarChooser stars={item?.seller_rating}  />
                                    <Commender rate={item?.seller_rating} />
                                </div>
                                <div className='rating'>
                                    <h6 className="text-center">Delivery Service</h6>
                                    <StarChooser stars={item?.delivery_rating} />
                                    <Commender rate={item?.delivery_rating} />
                                </div>

                            </div>
                            <div className='reviews mt-4 reviewsTab'>
                                <div>
                                    <h1>Photos</h1>
                                    <div className="upload__box">
                                        <div className="upload__img-wrap">
                                            {item?.images.map((img, index) => (
                                                <div key={index} className="upload__img-box">
                                                    <div
                                                        style={{
                                                            backgroundImage: `url(${img.original_url})`,
                                                            cursor: 'pointer',
                                                            width: '100px',
                                                            height: '100px',
                                                            backgroundSize: 'cover'
                                                        }}
                                                        onClick={() => handleOpenModal(img.original_url)}
                                                    />
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='mt-4  reviewsTab'>
                            <div className='writtenReview'>
                                <h1>Review</h1>
                                <textarea
                                    value={item?.remarks ? item.remarks : 'Empty....'}
                                    disabled
                                    rows={5}
                                    cols={70}
                                />
                            </div>
                            <h1>Descriptions</h1>
                            <div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px' }}>
                                {item.tags !== null && item.tags !== undefined && item.tags.length > 0 && item.tags.map((option) => (
                                    <button
                                        type="button"
                                        key={option}
                                        style={{
                                            padding: '10px 20px',
                                            backgroundColor: '#007BFF',
                                            color: '#FFF',
                                            border: '1px solid #007BFF',
                                            borderRadius: '5px',
                                            cursor: 'pointer',
                                        }}
                                    >
                                        {option}
                                    </button>
                                ))}
                            </div>
                            <button onClick={() => handleResubmitClick(item)} className='submitBtn'>
                                Resubmit
                            </button>
                        </div>

                    </div>

                ))}


            </div>
        </>


    );
}
const StarChooser = ({ stars, _onSubmit }) => {
    const getActiveStar = (value) => {
        return stars >= value ? "active" : "";
    };

    return (
        <div id="starfield">
            {[1, 2, 3, 4, 5].map(value => (
                <div
                    key={value}
                    className={`star ${getActiveStar(value)}`}
                    // onClick={() => _onSubmit(value)}
                >
                    ★
                </div>
            ))}
        </div>
    );
}

const Commender = ({ rate }) => {
    const [message, setMessage] = useState("");

    useEffect(() => {
        const messages = {
            1: "Terrible",
            2: "Poor",
            3: "Average",
            4: "Good",
            5: "Excellent :)"
        };

        setMessage(messages[rate] || "");
    }, [rate]);

    if (rate !== 0 && message.length) {
        return (
            <div id="commender">
                <p className="commender_tit">{message}</p>
            </div>
        );
    } else {
        return null;
    }
}

const mapStateToProps = (state) => ({
    customer_review_res: state.customer.customerReviewRes,
});

const mapDispatchToProps = (dispatch) => ({
    getCustomerDetail: () => dispatch(getCustomerDetail()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ReviewTab);
