import {useEffect, useState} from "react";
import PageTitle from "../Helpers/PageTitle";
import Layout from "../Partials/Layout";
import { Helmet } from "react-helmet-async";
import {getPage, getwebsitePage} from "../../actions/commonAction";
import {connect} from "react-redux";
import ReactHtmlParser from "react-html-parser";
import {useLocation} from "react-router-dom";
import BreadcrumbComProduct from "../Wishlist/BreadcrumbComProduct";
import ProductView from "../SingleProductPage/ProductView";

const WebPages = (props) => {
    const { className, type } = props;
    const [content, setContent] = useState("");
    const [title,setTitle] = useState("");
    const [description,setDescription] = useState("");
    const [metaTitle,setMetaTitle] = useState("");

    const location = useLocation();
    // Get the pathname from the location object
    const pathname = location.pathname;
    // Split the pathname by '/' and get the last element
    const lastParam = pathname.substring(pathname.lastIndexOf('/') + 1);

    useEffect(() => {
        // Fetch main category tree when the component mounts
        props.getwebsitePage(lastParam);
    }, [lastParam]);

    useEffect(() => {
        setContent(props.web_page_res?.data?.content)
        setTitle(props.web_page_res?.data?.title && '')
        setMetaTitle(props.web_page_res?.data?.meta_title)
        setDescription(props.web_page_res?.data?.meta_description)
    }, [props.web_page_res]);

    // For Scroll Top
    useEffect(() => {
        const scrollToTop = () => {
            window.scrollTo({
                top: 0,
                behavior: "smooth",
            });
        };
        scrollToTop();
    }, []);

    const path = props.web_page_res?.data?.path || "";
    const segments = path.split('/').filter(segment => segment !== '');
    const removeHtmlExtension = (str) => str.replace(/\.html$/, '');
    const removeHyphens = (str) => str.replace(/-/g, ' ');
    const removeHtmlTags = (str) => str.replace(/<[^>]*>?/gm, '');
    const paths = segments.map((segment, index) => ({
        name: removeHtmlExtension(removeHyphens(removeHtmlTags(segment))),
        path: `/${segments.slice(0, index + 1).join('/')}`,
    }));
    paths.unshift({
        name: 'Home',
        path: '/',
    });

    return (
        <>
            <Helmet prioritizeSeoTags>
                <meta charSet="utf-8" />
                <title>{metaTitle && metaTitle}</title>
                <meta property="og:title" content={metaTitle && metaTitle} />
                <meta property="og:description" content={description && description} />
                <meta name="title" content={metaTitle && metaTitle} />
                <meta
                    name="description"
                    content={description && description}
                />
            </Helmet>
            <Layout childrenClasses="pt-0 pb-0">
                <div className="terms-condition-page w-full bg-white pb-[30px]">
                    <div className="product-view-main-wrapper bg-white pt-[30px] w-full">
                        <div className="breadcrumb-wrapper w-full ">
                            <div className="container-x mx-auto">
                                <BreadcrumbComProduct paths={paths} title={props.web_page_res?.data?.title} />
                            </div>
                        </div>
                    </div>
                    <div className="w-full">
                        <div className="container-x mx-auto">
                            <div className="content-item w-full mb-10">
                                {ReactHtmlParser(content)}
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        </>
    );
}

const mapStateToProps = (state) => ({
    web_page_res: state.common.webPageRes,
});

const mapDispatchToProps = (dispatch) => ({
    getwebsitePage: (slug) => dispatch(getwebsitePage(slug)),
});

export default connect(mapStateToProps, mapDispatchToProps)(WebPages);

