import axios from 'axios';

// Create an instance of axios
const instance = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
    headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Credentials': false,
        'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
        'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept'
    }
});

let isRefreshing = false;
let subscribers = [];

function onTokenRefreshed(accessToken) {
    subscribers.forEach(callback => callback(accessToken));
    subscribers = [];
}

function addSubscriber(callback) {
    subscribers.push(callback);
}

async function refreshToken() {
    isRefreshing = true;
    try {
        const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/auth/login`, {
            email: process.env.REACT_APP_WEB_SITE_USER_EMAIL,
            password: process.env.REACT_APP_WEB_SITE_USER_PASSWORD
        });
        let newData = response.data.data;
        localStorage.setItem('authUserData', JSON.stringify(newData));
        onTokenRefreshed(newData.token);
        return newData.token;
    } catch (error) {
        // Handle refresh token failure (e.g., log out the user)
        return Promise.reject(error);
    } finally {
        isRefreshing = false;
    }
}

// Request Interceptor
instance.interceptors.request.use(async config => {
    let authUserData = JSON.parse(localStorage.getItem('authUserData'));

    if (authUserData?.token) {
        config.headers['Authorization'] = `Bearer ${authUserData.token}`;
    } else if (!isRefreshing) {
        try {
            const token = await refreshToken();
            config.headers['Authorization'] = `Bearer ${token}`;
        } catch (error) {
            // Handle error (e.g., redirect to login)
        }
    } else {
        // Wait for token refresh if already in progress
        return new Promise((resolve) => {
            addSubscriber(token => {
                config.headers['Authorization'] = `Bearer ${token}`;
                resolve(config);
            });
        });
    }

    return config;
}, error => Promise.reject(error));

// Response Interceptor to handle token expiration and 403 errors
instance.interceptors.response.use(response => response, async error => {
    const { config, response: { status } = {} } = error;
    const originalRequest = config;

    // Check if the response status is 403
    if (status === 403) {
        // Redirect to the 403 page
        window.location.href = '/403';
        return; // Stop further execution after the redirect
    }

    if (status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;

        if (isRefreshing) {
            return new Promise((resolve, reject) => {
                addSubscriber(token => {
                    originalRequest.headers['Authorization'] = `Bearer ${token}`;
                    resolve(axios(originalRequest));
                });
            });
        }

        try {
            const newToken = await refreshToken();
            originalRequest.headers['Authorization'] = `Bearer ${newToken}`;
            return axios(originalRequest);
        } catch (err) {
            // Handle the error (e.g., log out the user)
            return Promise.reject(err);
        }
    }

    return Promise.reject(error);
});

export default instance;
