import React from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import "./ClientReviewModal.css";
import { ImCross } from "react-icons/im";
import ReactHtmlParser from "react-html-parser";

const ClientReviewLearnMoreModal = ({ open, onClose, learnMoreData }) => {
  return (
    <Modal
      show={open}
      onHide={onClose}
      backdrop="true"
      size="lg"
      onExited={onClose}
      className={["client_review_modal", "learn_more"]}
      centered
    >
      <Modal.Header>
        <h3>Learn More</h3>
        <Button onClick={onClose}>
          <ImCross />
        </Button>
      </Modal.Header>
      <Modal.Body>
        <div className="w-full">
          <div className="container-x mx-auto">
            <div className="content-item w-full">
              <strong>How We Verify and Process Reviews</strong>
              <p>
                ETechBuy values its customer’s honest reviews and feedback. To
                ensure the reviews are authentic, we use ShopperApproved which
                not only reviews and publishes the reviews but also efficiently
                manages the reviews on seller ratings.
                <a
                  href="https://www.shopperapproved.com/reviews/etechbuy.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  https://www.shopperapproved.com/reviews/etechbuy.com
                </a>
              </p>
              <strong>Automated Review Requests</strong>
              <p>
                The review requests are sent to customers automatically through
                ShopperApproved who have placed the orders directly from the
                website ensuring that the reviews are reliable and authentic
                through technical verification methods like confirming the
                customer’s email address. Hence, the reviews posted through
                these requests are marked as “Verified Purchase” as well as
                there is a statement included that states “The review was
                collected from a verified customer who purchased this item”
              </p>
              <strong>Direct Website Submissions</strong>
              <p>
                The reviews posted directly on our website will not be marked as
                “verified”, rather there will be a statement “A site visitor
                wrote this review”{" "}
              </p>
              <strong>Imported Reviews</strong>
              <p>
                The views can also be imported to ShopperApproved through
                external sources. These reviews are displayed with the statement
                “This review was imported from an external source”{" "}
              </p>
              <strong>Verification of Reviews</strong>
              <p>
                The reviews received directly on the website or imported through
                external sources are verified through different verification
                methods such as asking the customer for proof of purchase. These
                reviews are displayed with the statement “This review was marked
                as verified by the store owner”{" "}
              </p>
              <strong>Rating System</strong>
              <p>
                The products can be rated on a scale of 1 to 5. The average
                score is calculated using a simple mathematical average and
                rounded to the nearest half or full star. The average is based
                on reviews where customers received discounts for adding images
                to their reviews or posting them on their social networks.
              </p>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ClientReviewLearnMoreModal;
