import BreadcrumbCom from "../BreadcrumbCom";
import Layout from "../Partials/Layout";

export default function FourZeroThree() {
  return (
        <div className="flex justify-center items-center w-full">
          <div>
            <div className="sm:mb-10 mb-0 transform sm:scale-100 scale-50">
              <img src={process.env.PUBLIC_URL + "/assets/images/fourZeroThree.png"} alt="empty-cart" />
            </div>
            <div data-aos="fade-up" className="empty-content w-full">
              <h1 className="sm:text-xl text-base font-semibold text-center mb-5">
                403 Forbidden
              </h1>
            </div>
          </div>
        </div>
  );
}
