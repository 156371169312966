import React, { useState, useEffect, useRef } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../SearchBox/AutoSearch.css';
import { getProductSearch } from '../../../actions/productAction';
import { connect } from 'react-redux';
import { useNavigate, Link } from 'react-router-dom';

const SearchBox = (props) => {
  const { className, type, getProductSearch, product_search_res } = props;
  const [query, setQuery] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const navigate = useNavigate();
  const suggestionListRef = useRef(null);

  useEffect(() => {
    const debounceTimer = setTimeout(() => {
      if (query !== '' && query.length > 2) {
        let data = { title: query };
        getProductSearch(data);
      }
    }, 500); // Adjust the delay (500ms) as needed

    return () => clearTimeout(debounceTimer);
  }, [query, getProductSearch]);

  useEffect(() => {
    let filterData = [];
    product_search_res.data.forEach((data) => {
      let result = {
        title: data.title,
        url: data.url,
      };
      filterData.push(result);
    });
    setSuggestions(filterData);
    setShowSuggestions(query.trim().length > 0);
  }, [query, product_search_res.data]);

  const handleInputChange = (event) => {
    setQuery(event.target.value);
  };

  const handleButtonClick = () => {
    setQuery('');
    navigate(`/search/${query}`);
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      setQuery('');
      navigate(`/search/${query}`);
    }
  };

  const handleClickOutsideSuggestions = (event) => {
    if (suggestionListRef.current && !suggestionListRef.current.contains(event.target)) {
      setShowSuggestions(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutsideSuggestions);

    return () => {
      document.removeEventListener('click', handleClickOutsideSuggestions);
    };
  }, []);

  return (
      <>
        <div
            className={`w-full h-full flex items-center border border-qgray-border bg-white ${
                className || ''
            }`}
        >
          <div className="flex-1 h-full">
            <form action="#" className="h-full">
              <input
                  type="text"
                  className="form-control search-input"
                  placeholder="Search Product..."
                  value={query}
                  onChange={handleInputChange}
                  onKeyPress={handleKeyPress}
              />
            </form>
            {showSuggestions && (
                <ul className="list-group suggestion-list" ref={suggestionListRef}>
                  {suggestions.map((suggestion) => (
                      <Link to={`/${suggestion.url}`} key={suggestion.url}>
                        <li className="list-group-item suggestion-item">
                          {suggestion.title}
                        </li>
                      </Link>
                  ))}
                </ul>
            )}
          </div>
          <div className="w-[1px] h-[22px] bg-qgray-border"></div>
          <div>
            <button
                className={`w-[93px] h-full text-sm font-600 btnSearchs ${
                    type === 3 ? 'bg-qh3-blue text-white' : 'search-btn'
                }`}
                type="button"
                onClick={handleButtonClick}
            >
              Search
            </button>
          </div>
        </div>
      </>
  );
};

const mapStateToProps = (state) => ({
  product_search_res: state.product.productSearchRes,
});

const mapDispatchToProps = (dispatch) => ({
  getProductSearch: (filters) => dispatch(getProductSearch(filters)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SearchBox);
