import BreadcrumbCom from "../BreadcrumbCom";
import EmptyCardError from "../EmptyCardError";
import InputCom from "../Helpers/InputCom";
import PageTitle from "../Helpers/PageTitle";
import Layout from "../Partials/Layout";
import ProductsTable from "./ProductsTable";
import { connect } from "react-redux";
import { useShoppingCart } from "use-shopping-cart";
import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import {
  getProductDetail,
  getRelatedProduct,
} from "../../actions/productAction";
import { toast } from "react-toastify";
import { Helmet } from "react-helmet-async";
import { countries, fedex, resetFedex } from "../../actions/commonAction";
import { useNavigate } from "react-router-dom";

const CardPage = (props) => {
  const {
    cartDetails,
    clearCart,
    removeItem,
    cartCount,
    totalPrice,
    addItem,
    setItemQuantity,
  } = useShoppingCart();
  const { cart = true, className, reportHandler, productDetail } = props;
  const [shippingCountryName, setShippingCountryName] =
    useState("United States");
  const [selectedCountry, setSelectedCountry] = useState(""); // State to track the selected country
  const [shippingCountries, setShippingCountries] = useState([]);
  const [fedexSelected, setFedexSelected] = useState("");
  const [fedexAmount, setFedexAmount] = useState(0);
  const [shippingOptions, setShippingOptions] = useState([]);
  const [taxAmount, setTaxAmount] = useState(0);
  const [taxPer, setTaxPer] = useState(0);
  const [productWeight, setProductWeight] = useState(0);

  const navigate = useNavigate();

  const handleProceedToCheckout = () => {
    //call reset fedex
    props.resetFedex();
    // Add any other logic you need before navigating
    navigate("/checkout");
  };

  const [formData, setFormData] = useState({
    customer_shipping_postcode: "",
    customer_shipping_country: "US",
  });

  const [formErrorData, setFormErrorData] = useState({
    customer_shipping_postcode_error: "",
    customer_shipping_country_error: "",
  });

  const handleCountryChange = (event) => {
    setSelectedCountry(event.target.value);
  };

  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };

  const query = useQuery();
  const productUrl = query.get("product-url");

  useEffect(() => {
    const fetchData = async () => {
      // Simulate API call
      await props.getProductDetail(productUrl);
    };

    if (productUrl) {
      fetchData();
    }
  }, [productUrl]);

  useEffect(() => {
    props.countries();
  }, []);

  useEffect(() => {
    const countriesData = props.country_res.data;
    // Map countries to objects with both name and iso2 properties
    const countriesWithIso2 = countriesData.map((country) => ({
      name: country.name,
      iso2: country.iso2,
    }));
    // Set the countries with iso2 values in state
    setShippingCountries(countriesWithIso2);
  }, [props.country_res]);

  const dropDownhandleChange = (e) => {
    const { name, type, checked, value, options } = e.target;

    // Get the attribute "dropDownName" from the selected option
    const dropDownName =
      options[e.target.selectedIndex]?.getAttribute("dropDownName");

    if (name === "customer_shipping_country") {
      setShippingCountryName(dropDownName);
    }

    // If the type is checkbox, set the value to the boolean checked state
    const newValue = type === "checkbox" ? checked : value;

    setFormData((prevData) => ({
      ...prevData,
      [name]: newValue,
    }));

    // Clear the error for this field if it's now filled
    if (newValue.trim() !== "") {
      setFormErrorData((prevData) => ({
        ...prevData,
        [`${name}_error`]: "",
      }));
    }
  };

  const handleChange = (e) => {
    const { name, type, checked, value } = e.target;
    const newValue = type === "checkbox" ? checked : value;

    // Update the form data
    setFormData((prevData) => ({
      ...prevData,
      [name]: newValue,
    }));
  };

  //calling fedex Api for shipping
  const handleCalculateShipping = () => {
    let sumWeight = 0;
    Object.values(cartDetails).forEach((cartDetail) => {
      sumWeight = sumWeight + cartDetail.product.weight * cartDetail.quantity;
    });
    setProductWeight(sumWeight);

    if (
      formData.customer_shipping_country !== "" &&
      formData.customer_shipping_postcode.length > 4 &&
      sumWeight !== 0
    ) {
      let fedexData = {
        type: "fedex",
        country_code: formData.customer_shipping_country,
        postal_code: formData.customer_shipping_postcode,
        unit: "LB",
        weight: sumWeight,
      };

      // Create a new URLSearchParams object
      const queryParams = new URLSearchParams();

      // Iterate over the fedexData object and append each key-value pair to the URLSearchParams
      Object.entries(fedexData).forEach(([key, value]) => {
        queryParams.append(key, value);
      });

      // Use 'await' with your asynchronous operation, assuming props.fedex returns a Promise
      props.fedex(queryParams);
    }
  };

  useEffect(() => {
    if (props.fedex_res?.success && formData.customer_shipping_country !== "US") {
      // If the fedex_res contains data, set default shipping options
      setFedexSelected(props.fedex_res.data[0]?.name); // Select first option by default
      setFedexAmount(props.fedex_res.data[0]?.amount); // Set first amount by default
    }else {
        setFedexSelected("Free Ground");
        setFedexAmount(0);
    }
  }, [props.fedex_res,formData.customer_shipping_country]);

  const handleShippingSelect = (event) => {
    const selectedValue = event.target.value;

    if (selectedValue === "Free Ground") {
      setFedexSelected(selectedValue);
      setFedexAmount(0); // Set the amount to 0 for free ground shipping
    } else {
      const selectedShippingOption = props.fedex_res.data.find(
        (option) => option.name === selectedValue
      );
      if (selectedShippingOption) {
        setFedexSelected(selectedShippingOption.name);
        setFedexAmount(selectedShippingOption.amount);
      }
    }
  };

  useEffect(() => {
    const data = props.product_detail.data;
    if (data && Object.keys(data).length > 0 && productUrl !== null) {
      let product = props.product_detail.data;
      let baseImage = null;
      if (product.product !== null) {
        product.product.media.forEach((mediaItem) => {
          const isBaseImage =
            mediaItem.custom_properties?.image_type?.includes("Base");
          if (isBaseImage) {
            baseImage = mediaItem.original_url;
            return; // Exit the loop once the base image is found
          }
        });
      }
      if (baseImage === null) {
        if (product?.website_category?.media) {
          if (product?.website_category?.media.length > 0) {
            let categoryMedia = product?.website_category?.media[0];
            baseImage = categoryMedia?.original_url;
          }
        }
      }

      let sell_price = "";
      const currentDate = new Date();
      const specialPriceFrom = new Date(product.special_price_from);
      const specialPriceTo = new Date(product.special_price_to);

      if (
        product.special_price !== null &&
        product.special_price !== 0 &&
        currentDate >= specialPriceFrom &&
        currentDate <= specialPriceTo
      ) {
        sell_price = product.special_price;
      } else {
        sell_price = product.sell_price;
      }

      let productData = {
        id: product.id,
        product_id: product.product_id,
        title: product.title,
        baseImage: baseImage,
        url: product.url,
        redirect_url: product.redirect_url,
        short_description: product.short_description,
        long_description: product.long_description,
        cost_price: product.cost_price,
        sell_price: product.sell_price,
        special_price: product.special_price,
        price: sell_price,
        discount: product.discount,
        qty: product.qty, // product stock quantity
        meta_title: product.meta_title,
        meta_description: product.meta_description,
        meta_keywords: product.meta_keywords,
        product: product.product,
        attributes: product.attributes,
      };

      // Call addItem function with productData as the first argument
      addItem(productData);
      toast.success(`${product.title} added to the cart!`, {
        position: "top-right",
        autoClose: 3000, // Close the notification after 3000 milliseconds (3 seconds)
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  }, [props.product_detail]);

  return (
    <>
      <Helmet>
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      <Layout childrenClasses={cart ? "pt-0 pb-0" : ""}>
        {cart === false ? (
          <div className="cart-page-wrapper w-full">
            <div className="container-x mx-auto">
              <BreadcrumbCom
                paths={[
                  { name: "home", path: "/" },
                  { name: "cart", path: "/cart" },
                ]}
              />
              <EmptyCardError />
            </div>
          </div>
        ) : (
          <div className="cart-page-wrapper w-full bg-white pb-[60px]">
            <div className="w-full">
              <PageTitle
                title="Your Cart"
                breadcrumb={[
                  { name: "home", path: "/" },
                  { name: "cart", path: "/cart" },
                ]}
              />
            </div>
            <div className="w-full mt-[23px]">
              <div className="container-x mx-auto">
                <div className="cart-page-wrapper-left">
                  <ProductsTable className="mb-[30px]" />
                  <div className="w-full sm:flex justify-end">
                    {/* <div className="discount-code sm:w-[270px] w-full mb-5 sm:mb-0 h-[50px] flex">
                  <div className="flex-1 h-full">
                    <InputCom type="text" placeholder="Discount Code" />
                  </div>
                  <button type="button" className="w-[90px] h-[50px] black-btn">
                    <span className="text-sm font-semibold">Apply</span>
                  </button>
                </div> */}
                    <div className="flex space-x-2.5 items-center">
                      <button
                        className="w-[220px] h-[50px] black-btn my_btn flex justify-center items-center"
                        onClick={handleProceedToCheckout}
                      >
                        <span className="text-sm font-semibold">
                          Proceed to Checkout
                        </span>
                      </button>

                      <Link to="/">
                        <div className="w-[220px] h-[50px] bg-[#0076bd] flex justify-center items-center text-white">
                          <span className="text-sm font-semibold">
                            Continue Shopping
                          </span>
                        </div>
                      </Link>

                      {/*<a href="#">*/}
                      {/*  <div className="w-[140px] h-[50px] bg-[#F6F6F6] flex justify-center items-center">*/}
                      {/*    <span className="text-sm font-semibold">Update Cart</span>*/}
                      {/*  </div>*/}
                      {/*</a>*/}
                    </div>
                  </div>
                </div>
                <div className="cart-page-wrapper-right">
                  <div className="w-full flex sm:justify-end">
                    <div className="sm:w-[370px] w-full border border-[#EDEDED] calculate_shipping_card_main">
                      {/*<div className="sub-total mb-6">*/}
                      {/*  <div className=" flex justify-between mb-6">*/}
                      {/*    <p className="text-[15px] font-medium text-qblack">*/}
                      {/*      Subtotal*/}
                      {/*    </p>*/}
                      {/*    <p className="text-[15px] font-medium text-qred">${totalPrice}</p>*/}
                      {/*  </div>*/}
                      {/*  <div className="w-full h-[1px] bg-[#EDEDED]"></div>*/}
                      {/*</div>*/}
                      <div className="shipping">
                        {/*<span className="text-[15px] font-medium text-qblack mb-[18px] block">*/}
                        {/*  Shipping*/}
                        {/*</span>*/}
                        <ul className="flex flex-col space-y-1">
                          {/*<li>*/}
                          {/*  <div className="flex justify-between items-center">*/}
                          {/*    <div className="flex space-x-2.5 items-center">*/}
                          {/*      <div className="input-radio">*/}
                          {/*        <input*/}
                          {/*          type="radio"*/}
                          {/*          name="price"*/}
                          {/*          className="accent-pink-500"*/}
                          {/*        />*/}
                          {/*      </div>*/}
                          {/*      <span className="text-[13px] text-normal text-qgraytwo">*/}
                          {/*        Free Shipping*/}
                          {/*      </span>*/}
                          {/*    </div>*/}
                          {/*    <span className="text-[13px] text-normal text-qgraytwo">*/}
                          {/*      +$00.00*/}
                          {/*    </span>*/}
                          {/*  </div>*/}
                          {/*</li>*/}
                          {/*<li>*/}
                          {/*  <div className="flex justify-between items-center">*/}
                          {/*    <div className="flex space-x-2.5 items-center">*/}
                          {/*      <div className="input-radio">*/}
                          {/*        <input*/}
                          {/*          type="radio"*/}
                          {/*          name="price"*/}
                          {/*          className="accent-pink-500"*/}
                          {/*        />*/}
                          {/*      </div>*/}
                          {/*      <span className="text-[13px] text-normal text-qgraytwo">*/}
                          {/*        Flat Rate*/}
                          {/*      </span>*/}
                          {/*    </div>*/}
                          {/*    <span className="text-[13px] text-normal text-qgraytwo">*/}
                          {/*      +$00.00*/}
                          {/*    </span>*/}
                          {/*  </div>*/}
                          {/*</li>*/}
                          {/*<li>*/}
                          {/*  <div className="flex justify-between items-center">*/}
                          {/*    <div className="flex space-x-2.5 items-center">*/}
                          {/*      <div className="input-radio">*/}
                          {/*        <input*/}
                          {/*          type="radio"*/}
                          {/*          name="price"*/}
                          {/*          className="accent-pink-500"*/}
                          {/*        />*/}
                          {/*      </div>*/}
                          {/*      <span className="text-[13px] text-normal text-qgraytwo">*/}
                          {/*        Local Delivery*/}
                          {/*      </span>*/}
                          {/*    </div>*/}
                          {/*    <span className="text-[13px] text-normal text-qgraytwo">*/}
                          {/*      +$00.00*/}
                          {/*    </span>*/}
                          {/*  </div>*/}
                          {/*</li>*/}
                        </ul>
                      </div>
                      <div className="shipping-calculation w-full">
                        <div className="title px-[30px] py-[1.5rem] bg-[#F6F6F6] border-[#EDEDED] border-b">
                          <h1 className="text-[18px] font-bold">
                            Calculate Shipping
                          </h1>
                        </div>
                        <div className="title px-[30px] bg-[#F6F6F6]">
                          <h1 className="text-[18px] font-bold">
                            Free Ground Shipping Within US
                          </h1>
                        </div>
                        <div className="px-[30px] py-[20px]">
                          <div className="w-full h-[40px] border rounded border-[#EDEDED] px-1 flex justify-between items-center">
                            {/*<span className="text-[13px] text-qgraytwo">Select Country</span>*/}
                            {/* <div className="relative"> */}
                            <select
                              className="text-[13px] rounded text-qgraytwo w-full px-2 h-[30px]"
                              autoComplete="new-customer-shipping-country"
                              id="customer_shipping_country"
                              name="customer_shipping_country"
                              value={formData.customer_shipping_country || "US"} // Set default value to 'US'
                              onChange={dropDownhandleChange}
                              style={{ fontWeight: "bold", color: "#000" }}
                            >
                              <option value="" disabled>
                                Select a country
                              </option>
                              {shippingCountries.map((country, index) => (
                                <option
                                  key={index}
                                  dropDownName={country.name}
                                  value={country.iso2}
                                >
                                  {country.name}
                                </option>
                              ))}
                            </select>
                            {/* </div> */}
                          </div>
                        </div>
                      </div>
                      <div className="calculate_shipping_main">
                        <div className="w-full h-[40px]">
                          <input
                            id="customer_shipping_postcode"
                            name="customer_shipping_postcode"
                            type="text"
                            placeholder="Zip Code*"
                            value={formData.customer_shipping_postcode} // Uncomment this line
                            onChange={handleChange}
                            className="w-full h-[40px] border border-[#EDEDED] px-2"
                          />
                        </div>
                        <button
                          type="button"
                          className="w-full"
                          onClick={handleCalculateShipping}
                        >
                          <div className=" h-[40px] bg-[#f5ad1d] flex justify-center items-center ">
                            <span className="text-sm font-semibold">
                              Calculate Shipping
                            </span>
                          </div>
                        </button>
                      </div>
                      {props.fedex_res?.data.length > 0 && (
                        <div className="shipping-calculation w-full mb-3 px-[30px]">
                          <div className="w-full">
                            <label htmlFor="shippingOptions">
                              Choose a Shipping Method:
                            </label>
                            <select
                                id="shippingOptions"
                                value={formData.customer_shipping_country === "US" && !fedexSelected ? "Free Ground" : fedexSelected}
                                onChange={handleShippingSelect}
                                className="text-[13px] rounded text-qgraytwo w-full px-2"
                                style={{ fontWeight: "bold", color: "#000" }}
                            >
                              <option value="" disabled>
                                Select a shipping method
                              </option>
                              {formData.customer_shipping_country === "US" && (
                                  <option
                                      style={{ fontWeight: "bold", color: "#000" }}
                                      value="Free Ground"
                                  >
                                    Free Ground - $0.00
                                  </option>
                              )}
                              {props.fedex_res.data.map((option, index) => (
                                  <option
                                      key={index}
                                      value={option.name}
                                      style={{ fontWeight: "bold", color: "#000" }}
                                  >
                                    {option.name} - ${option.amount.toFixed(2)}
                                  </option>
                              ))}
                            </select>

                          </div>
                        </div>
                      )}
                      <div className="total mb-6 px-[30px]">
                        <div className=" flex justify-between">
                          <p className="text-[13px] font-semibold text-qblack subtotal_label">
                            Subtotal
                          </p>
                          <p className="text-[15px] font-semibold text-qblack uppercase subtotal_amount">
                            ${totalPrice.toFixed(2)}
                          </p>
                        </div>
                        <div className="w-full">
                          <div className="sub-total">
                            <div className=" flex justify-between mt-[10px] mb-[10px]">
                              <div>
                                <p className="text-[13px] font-semibold text-qblack subtotal_label">
                                  Shipping
                                </p>
                              </div>
                              <p className="text-[15px] font-semibold text-qblack subtotal_amount">
                                ${fedexAmount}
                              </p>
                            </div>
                            {/*<div className=" flex justify-between mt-[10px] mb-[10px]">*/}
                            {/*  <div>*/}
                            {/*    <p className="text-[13px] font-semibold text-qblack subtotal_label">*/}
                            {/*      Tax*/}
                            {/*    </p>*/}
                            {/*  </div>*/}
                            {/*  <p className="text-[15px] font-semibold text-qblack subtotal_amount">*/}
                            {/*    ({taxPer}%) ${parseFloat(taxAmount).toFixed(2)}*/}
                            {/*  </p>*/}
                            {/*</div>*/}
                            <div className="mt-[10px]">
                              <div className=" flex justify-between mb-2">
                                <p className="text-2xl font-medium text-qblack total_label">
                                  Total
                                </p>
                                <p className="text-2xl font-medium total_amount">
                                  $
                                  {`${(
                                    parseFloat(totalPrice) +
                                    parseFloat(fedexAmount) +
                                    parseFloat(taxAmount)
                                  ).toFixed(2)}`}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="px-[30px] pb-[20px]">
                        <button
                          className="w-full h-[50px] black-btn flex justify-center items-center"
                          onClick={handleProceedToCheckout}
                        >
                          <span className="text-sm font-semibold">
                            Proceed to Checkout
                          </span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </Layout>
    </>
  );
};
const mapStateToProps = (state) => ({
  product_detail: state.product.productDetailRes,
  country_res: state.common.countryRes,
  fedex_res: state.common.fedexRes,
});

const mapDispatchToProps = (dispatch) => ({
  getProductDetail: (url) => dispatch(getProductDetail(url)),
  countries: () => dispatch(countries()),
  fedex: (data) => dispatch(fedex(data)),
  resetFedex: () => dispatch(resetFedex()),
});

export default connect(mapStateToProps, mapDispatchToProps)(CardPage);
