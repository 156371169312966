import { combineReducers } from 'redux';
import homeReducer from './homeReducer';
import commonReducer from "./commonReducer";
import productReducer from "./productReducer";
import customerReducer from "./customerReducer"; // Your home reducer file

export default combineReducers({
  home: homeReducer,
  common: commonReducer,
  product: productReducer,
  customer: customerReducer,
  // Add other reducers as needed
});
