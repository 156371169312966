import React, {useEffect, useRef, useState} from 'react';
import {connect} from "react-redux";
import {cardPayment, resetPaymentCard, squarePayment,} from "../../actions/commonAction";

const SquarePaymentForm = (props) => {
    const {taxPer,discountAmount,paymentMethodData,taxAmount,totalPrice,fedexAmount,setCardPaymentStatus,paymentMethod,name,phone,email,addressLines,city,state,countryCode,setCardPaymentResponse} = props;
    const appId = 'sq0idp-xiHX6rJt2GOZj4Z0cawSZg';
    const locationId = 'LCATKJJXD1Q9V';

    // const appId = 'sandbox-sq0idb-98Zh1JThC14OKC2xlqgo3A';
    // const locationId = 'LD1KN6W40DS0J';
    const latestPaymentMethod = useRef(null);
    const totalPaymentSquare = useRef(0);
    // const appId = 'sandbox-sq0idb-oNFuyzeU3p8ZHm_a0P09-w';
    // const locationId = 'LS0VTT9JP0Q35';

    // useEffect(()=>{
    //     props.resetPaymentCard();
    // },[])

    useEffect(() => {
        let totalP = totalPrice - discountAmount
        var net_amount = (parseFloat(totalP) + parseFloat(fedexAmount)+ parseFloat(taxAmount)).toFixed(2)
        totalPaymentSquare.current = net_amount;
    }, [discountAmount,taxAmount,totalPrice,fedexAmount]);


    useEffect(() => {
        latestPaymentMethod.current = paymentMethod;
    }, [paymentMethod]);

    useEffect(() => {
        if(paymentMethod === 'square'){
            if (props.card_res?.data?.success !== '') {
                const statusContainer = document.getElementById('payment-status-container');
                if (props.card_res?.data?.success === true) {
                    statusContainer.classList.remove('is-failure');
                    statusContainer.classList.add('is-success');
                    setCardPaymentResponse(props.card_res?.data?.data);
                    setCardPaymentStatus(props.card_res?.data?.success);
                    statusContainer.style.visibility = 'visible';
                } else if(props.card_res.success === false) {
                    statusContainer.classList.remove('is-success');
                    statusContainer.classList.add('is-failure');
                    setCardPaymentStatus(props.card_res?.data?.success);
                    setCardPaymentResponse(props.card_res?.data?.data);
                    // props.resetPaymentCard(); // Dispatch action outside of useEffect cleanup function
                    statusContainer.style.visibility = 'visible';
                }

            }
        }
    }, [props.card_res]); // Adjust dependencies



    useEffect(() => {
        const initializeCard = async (payments) => {
            const card = await payments.card();
            await card.attach('#card-container');

            return card;
        };

        const buildPaymentRequest = (payments) => {
            var net_amount = (totalPrice - discountAmount) + fedexAmount + taxAmount;
            return payments.paymentRequest({
                countryCode: 'US',
                currencyCode: 'USD',
                total: {
                    amount: Math.ceil(net_amount),
                    label: 'Total',
                },
            });
        };

        // const initializeApplePay = async (payments) => {
        //     const paymentRequest = buildPaymentRequest(payments);
        //     const applePay = await payments.applePay(paymentRequest);
        //     // Note: You do not need to `attach` applePay.
        //     return applePay;
        // };

        const createPayment = async (token,fixedNetamount) => {
            // if(latestPaymentMethod.current  === 'square'){
            // let totalP = totalPrice - discountAmount
            // var net_amount = (parseFloat(totalP) + parseFloat(fedexAmount)+ parseFloat(taxAmount)).toFixed(2);
            const data = {
                type: "square",
                reference_id: window.crypto.randomUUID(),
                amount:parseFloat(fixedNetamount).toFixed(2)*100,
                currency: 'USD',
                source_id: token,
            };
            props.squarePayment(data);
            // }
        };

        const tokenize = async (paymentMethod) => {
            const tokenResult = await paymentMethod.tokenize();
            if (tokenResult.status === 'OK') {
                return tokenResult.token;
            } else {
                let errorMessage = `Tokenization failed with status: ${tokenResult.status}`;
                if (tokenResult.errors) {
                    errorMessage += ` and errors: ${JSON.stringify(tokenResult.errors)}`;
                }

                throw new Error(errorMessage);
            }

        };


        const initializeSquare = async () => {
            if (!window.Square) {
                throw new Error('Square.js failed to load properly');
            }

            let payments;
            try {
                payments = window.Square.payments(appId, locationId);
            } catch {
                const statusContainer = document.getElementById('payment-status-container');
                statusContainer.className = 'missing-credentials';
                statusContainer.style.visibility = 'visible';
                return;
            }

            let card;
            try {
                card = await initializeCard(payments);
            } catch (e) {
                console.error('Initializing Card failed', e);
                return;
            }

            // let applePay;
            // try {
            //     applePay = await initializeApplePay(payments);
            // } catch (e) {
            //     console.error('Initializing Apple Pay failed', e);
            //     // Handle initialization failures while still loading other applicable payment methods
            // }

            const handlePaymentMethodSubmission = async (event, paymentMethod) => {
                event.preventDefault();

                try {
                    // Disable the submit button as we await tokenization and make a payment request
                    // cardButton.disabled = true;
                    const token = await tokenize(paymentMethod);
                    const paymentResults = await createPayment(token,totalPaymentSquare.current);

                } catch (e) {
                    cardButton.disabled = false;
                    console.error(e.message);
                }
            };

            const cardButton = document.getElementById('card-button');
            if (cardButton) {
                cardButton.addEventListener('click', async (event) => {
                    await handlePaymentMethodSubmission(event, card);
                });
            }




            // Checkpoint 2.
            // if (applePay) {
            //     const applePayButton = document.getElementById('apple-pay-button');
            //     applePayButton.addEventListener('click', async (event) => {
            //         await handlePaymentMethodSubmission(event, applePay);
            //     });
            // }
        };

        initializeSquare();
    }, []); // Empty dependency array ensures useEffect runs only once when the component mounts

    return (
        <div className={paymentMethod === 'square' ? 'square-payment-box-show' : 'square-payment-box-hide'}>
            {/*<form id="payment-form">*/}
            <div id="card-container"></div>
            {/*<button id="card-button" type="button" className={'black-btn'}>*/}
            {/*    Pay  ${`${(parseFloat(totalPrice) + parseFloat(fedexAmount)).toFixed(2)}`}*/}
            {/*</button>*/}
            {/*</form>*/}
            <div id="payment-status-container"></div>
        </div>
    );
};

const mapStateToProps = (state) => ({
    card_res: state.common.cardRes
});

const mapDispatchToProps = (dispatch) => ({
    squarePayment: (jsondata) => dispatch(cardPayment(jsondata)),
    // resetPaymentCard: () => dispatch(resetPaymentCard()),
});

export default connect(mapStateToProps, mapDispatchToProps)(SquarePaymentForm);

