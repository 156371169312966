import React, { useState, useEffect } from 'react';
import "../tabs/tabs.css";
import {
    getCustomerOrders,
    postReview,
    postReviewReset,
    updateReview,
    updateReviewReset
} from "../../../../actions/customerAction";
import {connect} from "react-redux";
import {useLocation, useNavigate} from 'react-router-dom';
import {toast} from "react-toastify";


const FeedBackForm = (props) => {
    const location = useLocation();
    const navigate = useNavigate();
    const user_data = JSON.parse(localStorage.getItem("user_data"));

    const resubmitData = location.state?.resubmitData;
    const [productRating, setProductRating] = useState(resubmitData?.product_rating ?? 0);
    const [sellerServiceRating, setSellerServiceRating] = useState(resubmitData?.seller_rating ?? 0);
    const [deliveryServiceRating, setDeliveryServiceRating] = useState(resubmitData?.delivery_rating ?? 0);
    const [textAreaValue, setTextAreaValue] = useState(resubmitData?.remarks ?? '');
    const [buttonText, setButtonText] = useState('Review Submitted');

    const product = location.state?.product;

    const selectedOrder = location.state?.selectedOrder;
    const handleTextAreaChange = (e) => {
        setTextAreaValue(e.target.value);
    };

    const [selectedOptions, setSelectedOptions] = useState(resubmitData?.tags ?? []);

    const options = [
        'compatible product',
        'fast delivery',
        'excellent customer service',
        'best price',
        'express delivery',
        'hard to find item',
        'genuine product',
        'bulk order fulfillment',
    ];

    const handleButtonClick = (option) => {
        setSelectedOptions((prev) =>
            prev.includes(option) ? prev.filter((o) => o !== option) : [...prev, option]
        );
    };

    const handleSubmitForm = () => {
        setButtonText('Processing...');
        const formData = new FormData();
        // Append files from imgArray to formData with custom keys
        imgArray.forEach((file, index) => {
            if (file instanceof File) {
                formData.append(`images[${index}][file]`, file);
            } else {
                console.warn(`Invalid file at index ${index}:`, file);
            }
        });

        selectedOptions.forEach((value,index)=> {
            formData.append(`tags[${index}]`, value);
        });

        // Append other fields to formData as needed
        formData.append('created_by',user_data?.user?.firstname);
        formData.append('website_order_id', selectedOrder.id);
        formData.append('website_order_product_id', product.id);
        formData.append('product_rating', productRating);
        formData.append('seller_rating', sellerServiceRating);
        formData.append('delivery_rating', deliveryServiceRating);
        formData.append('remarks', textAreaValue);
        formData.append('show_username', isChecked ? 1 : 0);
        formData.append('active', 1);
        formData.append('status', "pending");


        props.postReview(formData);
    }

   //form submit function
    const reSubmitForm = () => {
        setButtonText('Processing...');

        // Create a FormData object to hold your data
        const formData = new FormData();
        formData.append('_method', 'PUT');
        formData.append('product_rating', productRating);
        formData.append('seller_rating', sellerServiceRating);
        formData.append('delivery_rating', deliveryServiceRating);
        formData.append('remarks', textAreaValue);
        formData.append('show_username', isChecked ? 1 : 0);
        formData.append('active', 1);
        formData.append('status', 'pending');

        // Conditionally add order IDs
        if (resubmitData !== undefined) {
            formData.append('website_order_id', resubmitData.website_order_id);
            formData.append('website_order_product_id', resubmitData.website_order_product_id);
        } else {
            formData.append('website_order_id', product.id);
            formData.append('website_order_product_id', selectedOrder.id);
        }

        // Append tags
        selectedOptions.forEach((value, index) => {
            formData.append(`tags[${index}]`, value);
        });

        // Append files from imgArray
        imgArray.forEach((file, index) => {
            if (file instanceof File) {
                console.log('file', file);
                formData.append(`images[${index}][file]`, file);
            } else {
                console.warn(`Invalid file at index ${index}:`, file);
            }
        });

        props.updateReview(formData, resubmitData.id);
    };

    useEffect(() => {
        if(props.post_review_res !== undefined && props.post_review_res.data !== undefined){
            props.postReviewReset();
            //your review has been submitted successfully
            setButtonText('Review Submit');
            toast.success(`Your Review Has Been Submitted Successfully!`, {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
            navigate('/profile#review');
        } if(props.update_review_res !== undefined && props.update_review_res.data !== undefined){
            props.updateReviewReset();
            //your review has been submitted successfully
            setButtonText('Review Resubmit');
            toast.success(`Your Review Has Been Resubmitted Successfully!`, {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
            navigate('/profile#review');
        }
    },[props.post_review_res,props.update_review_res]);

    const [isChecked, setIsChecked] = useState(true);
    const CheckboxComponent = () => {
        // Define a state to hold the checkbox value

        // Function to handle checkbox change
        const handleCheckboxChange = (event) => {
            setIsChecked(event.target.checked);
        };

        return (
            <div className='showName'>
                <label style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}>
                    <input
                        type="checkbox"
                        checked={isChecked}
                        onChange={handleCheckboxChange}
                        style={{ marginRight: '8px' }}
                    />
                    <p>
                        Show Username
                    </p>
                </label>
            </div>
        );
    }

    const [imgArray, setImgArray] = useState([]);
    const ImageUpload = () => {

        const handleFileChange = (e) => {
            const files = e.target.files;
            const filesArr = Array.from(files);
            const maxLength = e.target.getAttribute('data-max_length');

            let newImgArray = [...imgArray];
            let iterator = 0;

            filesArr.forEach((f) => {
                if (!f.type.match('image.*')) {
                    return;
                }

                if (newImgArray.length >= maxLength) {
                    return false;
                } else {
                    newImgArray.push(f);

                    const reader = new FileReader();
                    reader.onload = (e) => {
                        const imgWrap = e.target.result;
                        newImgArray = [...newImgArray]; // Make sure to update the array
                        setImgArray(newImgArray);
                        iterator++;
                    };
                    reader.readAsDataURL(f);
                }
            });
        };

        const removeImage = (file) => {
            const newImgArray = imgArray.filter(img => img.name !== file);
            setImgArray(newImgArray);
        };

        return (
            <div className="upload__box">
                <div className="upload__btn-box">
                    <label className="upload__btn">
                        Upload Images
                        <input
                            className="upload__inputfile"
                            type="file"
                            multiple
                            data-max_length="10"
                            onChange={handleFileChange}
                        />
                    </label>
                </div>
                <div className="upload__img-wrap">
                    {imgArray.map((img, index) => (
                        <div key={index} className="upload__img-box">
                            <div
                                style={{ backgroundImage: `url(${URL.createObjectURL(img)})` }}
                                data-number={index}
                                data-file={img.name}
                                className="img-bg">
                                <div className="upload__img-close" onClick={() => removeImage(img.name)}></div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        );
    }

    return (
        <div className='profileTab'>
            <div className='reviews'>
                <h2 className='font-bold text-[24px] text-qblack mb-2'>My Reviews</h2>
                <div>
                    <div className='reviewsTab'>
                        {/*<div className='reviewBox'>*/}
                        {/*    <img*/}
                        {/*        src={`${process.env.PUBLIC_URL}/assets/images/card-1.svg`}*/}
                        {/*        alt="payment"*/}
                        {/*    />*/}
                        {/*    <div className='pl-3'>*/}
                        {/*        <h6>WS-C3560X-24P-L WS-C3560X-24P-L</h6>*/}
                        {/*        <p>WS-C3560X-24P-L Cisco C3560X-24P 24-Port + -Port Layer 3 Switch</p>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                        <div className='rating pt-3'>
                            <h6>Select Product Rating</h6>
                            <StarChooser stars={productRating} _onSubmit={setProductRating} />
                            <Commender rate={productRating} />
                        </div>
                    </div>
                </div>
            </div>
            <div className='reviews mt-4'>
                <div className='reviewsTab ratingsReviews'>
                    <div className='rating'>
                        <h6>Seller Service</h6>
                        <StarChooser stars={sellerServiceRating} _onSubmit={setSellerServiceRating} />
                        <Commender rate={sellerServiceRating} />
                    </div>
                    <div className='rating'>
                        <h6>Delivery Service</h6>
                        <StarChooser stars={deliveryServiceRating} _onSubmit={setDeliveryServiceRating} />
                        <Commender rate={deliveryServiceRating} />
                    </div>
                </div>
            </div>
            <div className='reviews mt-4 reviewsTab'>
                <div>
                    <h1>Add Photos and Video</h1>
                    <div>
                        <ImageUpload />
                    </div>
                </div>
            </div>
            <div className='mt-4  reviewsTab'>
                <div className='writtenReview'>
                    <h1>Add Written Review</h1>
                    <textarea
                        value={textAreaValue}
                        onChange={handleTextAreaChange}
                        placeholder="Enter your text here..."
                        rows={5}
                        cols={70}
                    />
                </div>
            </div>
            <div className='mt-4  reviewsTab'>
                <h1>Select Descriptions</h1>
                <div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px' }}>
                    {options.map((option) => (
                        <button
                            type="button"
                            key={option}
                            onClick={() => handleButtonClick(option)}
                            style={{
                                padding: '10px 20px',
                                backgroundColor: selectedOptions.includes(option) ? '#007BFF' : '#FFF',
                                color: selectedOptions.includes(option) ? '#FFF' : '#000',
                                border: '1px solid #007BFF',
                                borderRadius: '5px',
                                cursor: 'pointer',
                            }}
                        >
                            {option}
                        </button>
                    ))}
                </div>
            </div>
            <div className='reviews mt-4 reviewsTab'>
                <div>
                    <h1>Add Photos and Video</h1>

                    <CheckboxComponent />
                </div>
            </div>
            <button onClick={resubmitData !== undefined ? reSubmitForm :handleSubmitForm} className='submitBtn'>
                {buttonText}
            </button>
        </div>
    );
}
const StarChooser = ({ stars, _onSubmit }) => {
    const getActiveStar = (value) => {
        return stars >= value ? "active" : "";
    };

    return (
        <div id="starfield">
            {[1, 2, 3, 4, 5].map(value => (
                <div
                    key={value}
                    className={`star ${getActiveStar(value)}`}
                    onClick={() => _onSubmit(value)}
                >
                    ★
                </div>
            ))}
        </div>
    );
}

const Commender = ({ rate }) => {
    const [message, setMessage] = useState("");

    useEffect(() => {
        const messages = {
            1: "Terrible",
            2: "Poor",
            3: "Average",
            4: "Good",
            5: "Excellent :)"
        };

        setMessage(messages[rate] || "");
    }, [rate]);

    if (rate !== 0 && message.length) {
        return (
            <div id="commender">
                <p className="commender_tit">{message}</p>
            </div>
        );
    } else {
        return null;
    }
}

const mapStateToProps = (state) => ({
    post_review_res: state.customer.postReviewRes,
    update_review_res: state.customer.updateReviewRes,
});

const mapDispatchToProps = (dispatch) => ({
    postReview: (data) => dispatch(postReview(data)),
    postReviewReset: (data) => dispatch(postReviewReset()),
    updateReview: (data,id) => dispatch(updateReview(data,id)),
    updateReviewReset: (data) => dispatch(updateReviewReset()),
});

export default connect(mapStateToProps, mapDispatchToProps)(FeedBackForm);
