import React from 'react';
import { Link } from 'react-router-dom';
import ImageWithFallback from "../ImageWithFallback";

const ProductsAds = ({ className, ads = [], sectionHeight }) => {
    return (
        <div className={`w-full ${className || ""}`}>
            <div className="container-x mx-auto">
                <div
                    className={`${sectionHeight} ${ads.length > 1 && ads.length <= 2
                        ? "sm:flex xl:space-x-[30px] sm:space-x-5"
                        : ""
                    } items-center w-full  overflow-hidden`}
                >
                    <div
                        data-aos="fade-right"
                        className={`h-full sm:mb-0 ${ads.length > 1 && ads.length <= 2 ? "sm:w-1/2 w-full" : "w-full"
                        }  `}
                    >
                        <Link to={ads[0]?.button_url || '/'}>
                            <img src={ads[0]?.imageUrl} alt="Product Ads Image" className="w-full sm:h-full h-auto" loading="lazy" />
                        </Link>
                    </div>
                    {ads.length > 1 && ads.length <= 2 && (
                        <div data-aos="fade-left" className="flex-1 h-full">
                            <Link to={ads[1]?.button_url || '/'}>
                                <img src={ads[1]?.imageUrl} alt="Product Ads Image" className="w-full h-full" loading="lazy" />
                            </Link>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

export default ProductsAds;
