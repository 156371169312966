import EmailIcon from "@mui/icons-material/Email";
import { useEffect, useState } from "react";

export default function TopBar({ className, isSticky }) {
  const [webSiteData, setWebSiteData] = useState(null);

  let authUserData = localStorage.getItem("authUserData");

  useEffect(() => {
    authUserData = authUserData ? JSON.parse(authUserData) : null;

    // authUserData.user has multiple website data; want to set data by URL match
    if (authUserData && authUserData.user && authUserData.user.websites) {
      let currentWebsite = authUserData.user.websites.find((website) => {
        return website.url === process.env.REACT_APP_DOMAIN_NAME;
      });
      if (currentWebsite) {
        setWebSiteData(currentWebsite);
      }
    }
  }, [authUserData]);

  return (
    <>
      <div className={`w-full bg-white h-4 ${className || ""}`}>
        <div className="mx-auto h-full">
          <div className="flex justify-between items-center h-full topbax">
            <div className="topbar-nav">
              {/* <ul className="flex space-x-6">
                <li>
                  <Link to="/">
                    <span className="text-xs leading-6 text-qblack font-500">
                      Account
                    </span>
                  </Link>
                </li>
                <li>
                  <Link to="/tracking-order">
                    <span className="text-xs leading-6 text-qblack font-500">
                      Track Order
                    </span>
                  </Link>
                </li>
                <li>
                  <Link to="/faq">
                    <span className="text-xs leading-6 text-qblack font-500">
                      Support
                    </span>
                  </Link>
                </li>
              </ul> */}
            </div>
            <div className="topbar-dropdowns sm:block hidden">
              <div className="flex space-x-6 pt-4">
                {/* <div className="country-select flex space-x-1 items-center">
                  <div>
                    <img
                      src={`${process.env.PUBLIC_URL}/assets/images/country-logo-16x16.png`}
                      width="16"
                      height="16"
                      alt="country logo"
                      className="overflow-hidden rounded-full"
                    />
                  </div>
                  <Selectbox
                    className="w-fit"
                    datas={["United State", "Bangladesh", "India"]}
                  />
                  <div>
                    <Arrow className="fill-current qblack" />
                  </div>
                </div>
                <div className="currency-select flex space-x-1 items-center">
                  <Selectbox className="w-fit" datas={["USD", "BDT"]} />
                  <Arrow className="fill-current qblack" />
                </div>
                <div className="language-select flex space-x-1 items-center">
                  <Selectbox className="w-fit" datas={["Bangla", "English"]} />
                  <Arrow className="fill-current qblack" />
                </div> */}
                {!isSticky && (
                  <>
                    <div className="emails">
                      <EmailIcon />
                      <a href={`mailto:${webSiteData?.config?.support_email}`}>
                        {webSiteData?.config?.support_email}
                      </a>
                    </div>
                  </>
                )}
                {/*<div className="liveChats">*/}
                {/*<TextsmsIcon/>*/}
                {/*<Link to="/">Live Chat */}
                {/*  </Link> */}
                {/*</div>*/}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
