import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { FaEye } from 'react-icons/fa';
import './model.css';
const Modal = ({ show, onClose, selectedProduct, onReview }) => {
    const modalStyle = {
        display: show ? 'block' : 'none'
    };

    return (
        <div className="modal" style={modalStyle}>
            <div className="modal-content">
                <span className="close" onClick={onClose}>&times;</span>
                <h1>Products</h1>
                <table className="table table-bordered table-striped">
                    <thead>
                    <tr>
                        <th scope="col">Title</th>
                        <th scope="col">View</th>
                        <th scope="col">Review</th>
                    </tr>
                    </thead>
                    <tbody>
                    {selectedProduct.map((product, index) => (
                        <tr key={index}>
                            <td>{product.website_product.title}</td>
                            <td>
                                <Link to={"/" + product.website_product?.url}>
                                    <FaEye style={{ color: '#08456B', margin: '0 auto', display: 'table' }} />
                                </Link>
                            </td>
                            <td>
                                <button
                                    type="button"
                                    className="w-[116px] h-[46px] bg-qyellow text-qblack font-bold"
                                    onClick={() => onReview(product)}
                                >
                                    Review
                                </button>
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default Modal;
