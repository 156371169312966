import React, { useState, useEffect ,useRef} from 'react';
import axios from 'axios';
import { connect } from "react-redux";
import {cardPayment, resetOrder, resetPaymentCard} from "../../actions/commonAction";

const QuickBooksPaymentForm = (props) => {
    const { username, postcode, country, address1, taxPer, discountAmount, taxAmount, totalPrice, fedexAmount, setCardPaymentStatus, paymentMethod, name, phone, email, addressLines, city, state, countryCode, setCardPaymentResponse } = props;
    const [tokenResponse, setTokenResponse] = useState("");
    const [cardTokenError, setCardTokenError] = useState("");
    const [cardTokenSuccess, setCardTokenSuccess] = useState("");
    const [cardToken, setCardToken] = useState(null);
    const [cardPaymentSuccess, setCardPaymentSuccess] = useState("");
    const [cardPaymentError, setCardPaymentError] = useState("");
    // const latestCardToken = useRef(null);
    const latestPaymentMethod = useRef(null);
    const totalPaymentQbook = useRef(0);
    const lastQuickNumber = useRef(0);
    const lastQuickExpMonth = useRef(0);
    const lastQuickExpYear = useRef(0);
    const lastQuickCvc = useRef(0);



    useEffect(() => {
        // latestCardToken.current = cardToken;
        latestPaymentMethod.current = paymentMethod;
    }, [cardToken,paymentMethod]);

    useEffect(() => {
        let totalP = totalPrice - discountAmount
        var net_amount = (parseFloat(totalP) + parseFloat(fedexAmount)+ parseFloat(taxAmount)).toFixed(2)
        totalPaymentQbook.current = net_amount;
    }, [discountAmount,taxAmount,totalPrice,fedexAmount]);

    const [formData, setFormData] = useState({
        quick_name: '',
        quick_number: '',
        quick_expMonth: '',
        quick_expYear: '',
        quick_cvc: ''
    });

    // useEffect(()=>{
    //     props.resetPaymentCard();
    // },[])

    useEffect(()=>{
        if(paymentMethod === 'quickbook'){
            if(props.card_token_res?.data?.success !== ''){
                if(props.card_token_res?.data?.success === true){
                    setCardPaymentSuccess("Payment Success");
                    setCardPaymentError("");
                    setCardPaymentResponse(props.card_token_res?.data?.data);
                    setCardPaymentStatus(props.card_token_res?.data?.success);
                }else if(props.card_token_res?.success === false){
                    setCardPaymentSuccess("");
                    setCardPaymentError("Payment Failed!");
                    setCardPaymentStatus(props.card_token_res?.data?.success);
                    setCardPaymentResponse(props.card_token_res?.data?.data);
                }
            }
        }
    },[props.card_token_res])

    const handleChange = (e) => {
        const { name, value } = e.target;
        if(name === 'quick_number'){
            lastQuickNumber.current = value
        }else if(name === 'quick_expMonth'){
            lastQuickExpMonth.current = value
        }else if(name === 'quick_expYear'){
            lastQuickExpYear.current = value
        }else if(name === 'quick_cvc'){
            lastQuickCvc.current = value
        }
        setFormData({ ...formData, [name]: value });
    };

    // useEffect(() => {
    //     if(latestPaymentMethod.current === 'quickbook'){
    //         if (formData.quick_cvc.length === 3 || formData.quick_cvc.length === 4) {
    //             handleTokenSubmit();
    //         }
    //     }
    //
    // }, [formData.quick_cvc,formData.quick_number,formData.quick_expMonth,formData.quick_expYear]);

    useEffect(() => {
        if(tokenResponse.value !== undefined && tokenResponse.value !== "" ){
            setCardTokenSuccess("Card Token Verified Successfully!")
            setCardToken(tokenResponse?.value)
            setCardTokenError("")
            paymentHandle(tokenResponse?.value,totalPaymentQbook.current);
        }else if(tokenResponse?.errors && tokenResponse?.errors.length >0){
            setCardTokenSuccess("")
            setCardTokenError(tokenResponse?.errors[0].message)
        }
        setTimeout(() => {
            setCardTokenSuccess("")
            setCardTokenError("")
        }, 4000);

    }, [tokenResponse]);

    useEffect(() => {
        const cardButton = document.getElementById('quickbook-button');
        if (cardButton) {
            cardButton.addEventListener('click', () => {
                if (paymentMethod === "quickbook") {
                    handleTokenSubmit(lastQuickNumber.current,lastQuickExpMonth.current,lastQuickExpYear.current,lastQuickCvc.current)
                }
            });
        }

        // return () => {
        //     if (cardButton) {
        //         cardButton.removeEventListener('click', handleTokenSubmit);
        //     }
        // };
    }, [paymentMethod]);

    const paymentHandle = (cardToken,nextFixAmount) => {
        if(latestPaymentMethod.current === "quickbook"){
            setTimeout(() => {
                // let totalP = totalPrice - discountAmount
                // var net_amount = (parseFloat(totalP) + parseFloat(fedexAmount)+ parseFloat(taxAmount)).toFixed(2)
                const data = {
                    type: "quickbook",
                    amount: Math.ceil(nextFixAmount),
                    currency: 'USD',
                    token: cardToken,
                };
                props.qbookPayment(data);
            }, 800);
        }
    };

    const handleTokenSubmit = async (quick_number,quick_expMonth,quick_expYear,quick_cvc) => {

        try {
            const response = await axios.post('https://api.intuit.com/quickbooks/v4/payments/tokens', {
                card: {
                    name: username,
                    number: quick_number,
                    expMonth: quick_expMonth,
                    address: {
                        postalCode: postcode,
                        country: country,
                        region: state,
                        streetAddress: address1,
                        city: city
                    },
                    expYear: quick_expYear,
                    cvc: quick_cvc
                }
            }, {
                headers: { 'Content-Type': 'application/json' }
            });
            setTokenResponse(response.data)
            // Set token result in state or handle it as needed
            // Example: setTokenResult(response.data);
        } catch (error) {
            // If the error has a response object, it means that an error response was received from the server
            if (error.response) {
                // Access the error response data
                // Handle error state or display error message
                // Example: setError('Error creating token: ' + error.response.data.message);
                setTokenResponse(error.response.data);
            } else {
                // If the error does not have a response object, it means that an error occurred before the response was received
                // Handle error state or display error message
                // Example: setError('Request error: ' + error.message);
                setTokenResponse('Request error: ' + error.message);
            }
        }
    };

    return (
        <div className={paymentMethod === 'quickbook' ? 'ebook-payment-box-show' : 'ebook-payment-box-hide'}>
            <form action="" method="POST">
        {/* Input fields for card details */}
        <div className="formBox">
          {/* <label>Number</label> */}
          <input
            id="quick_number"
            type="text"
            name="quick_number"
            maxLength="16"
            placeholder="Enter card number"
            required
            value={formData.quick_number}
            onChange={handleChange}
            style={{ outline: "none", height: "40px", padding: "10px" }}
          />
          <div style={{ display: "flex" }}>
            {/* <label>Expiry Month</label> */}
            <input
              id="quick_expMonth"
              type="text"
              name="quick_expMonth"
              placeholder="MM"
              maxLength="2"
              required
              value={formData.quick_expMonth}
              onChange={handleChange}
              style={{ outline: "none", height: "40px", padding: "10px" }}
            />
            {/* <label>Expiry Year</label> */}
            <input
              id="quick_expYear"
              type="text"
              name="quick_expYear"
              placeholder="YYYY"
              maxLength="4"
              required
              value={formData.quick_expYear}
              onChange={handleChange}
              style={{ outline: "none", height: "40px", padding: "10px" }}
            />
            {/* <label>CVC</label> */}
            <input
              id="quick_cvc"
              type="text"
              name="quick_cvc"
              placeholder="Enter CVC"
              maxLength="5"
              required
              value={formData.quick_cvc}
              onChange={handleChange}
              style={{ outline: "none", height: "40px", padding: "10px" }}
            />
          </div>
        </div>

        <br />

        {/*<button type="button" onClick={handleSubmit}>Tokenize</button>*/}
      </form>
      <div>
        <span className="text-success">{cardTokenSuccess}</span>
        <span className="text-danger">{cardTokenError}</span>
        <span className="text-success">{cardPaymentSuccess}</span>
        <span className="text-danger">{cardPaymentError}</span>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
    card_token_res: state.common.cardRes
});

const mapDispatchToProps = (dispatch) => ({
    qbookPayment: (jsondata) => dispatch(cardPayment(jsondata)),
    // resetPaymentCard: () => dispatch(resetPaymentCard()),

});

export default connect(mapStateToProps, mapDispatchToProps)(QuickBooksPaymentForm);
