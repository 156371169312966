import PageTitle from "../Helpers/PageTitle";
import Layout from "../Partials/Layout";
import {getFaq, getPage, getReturnpolicies} from "../../actions/commonAction";
import { connect } from "react-redux";
import {useEffect, useState} from "react";
import ReactHtmlParser from "react-html-parser";
import { Helmet } from "react-helmet-async";

const ReturnPolicies = (props) => {
  const [content, setContent] = useState("");

  useEffect(() => {
    // Fetch main category tree when the component mounts
    props.getPage("return-policies");
  }, []);

  useEffect(() => {
    setContent(props.page_res?.data?.content)
  }, [props.page_res]);

  // For Scroll Top
  useEffect(() => {
    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    };
    scrollToTop();
  }, []);

  return (
    <>
      <Helmet prioritizeSeoTags>
        <meta charSet="utf-8" />
        <title>Return Policy | Making Computing Better | ETechBuy.com </title>
        <meta property="og:title" content={'Return Policy | Making Computing Better | ETechBuy.com '} />
        <meta property="og:description" content="One-stop shop for Computer Hardware | Explore ETechBuy Policies" />

        <meta name="title" content={'Return Policy | Making Computing Better | ETechBuy.com '} />

        <meta
          name="description"
          content="One-stop shop for Computer Hardware | Explore ETechBuy Policies"
        />
      </Helmet>
      <Layout childrenClasses="pt-0 pb-0">
        <div className="terms-condition-page w-full bg-white pb-[30px]">
          <div className="w-full mb-[30px]">
            <PageTitle
              // breadcrumb={[
              //   { name: "home", path: "/" },
              //   { name: "Return Policy", path: "/return-policies" },
              // ]}
              title="Return Policy"
            />
          </div>
          <div className="w-full">
            <div className="container-x mx-auto">
              {ReactHtmlParser(content)}
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

const mapStateToProps = (state) => ({
  page_res: state.common.pageRes,
});

const mapDispatchToProps = (dispatch) => ({
  getPage: (slug) => dispatch(getPage(slug)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ReturnPolicies);
