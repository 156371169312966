import React, { useEffect, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";

const BankOfAmerica = ({
  contextToken,
  onPaymentProcessed,
  formData,
  fedexSelected,
  orderStatus,
  paymentTransactionRes,
}) => {
  const [captchaToken, setCaptchaToken] = useState("");
  useEffect(() => {
    // Capture context from token
    const captureContext = contextToken;
    const loadingIcon = document.getElementById("loadingIcon");
    const payButton = document.getElementById("pay-button");

    const myStyles = {
      input: {
        fontSize: "14px",
        fontFamily: "helvetica, tahoma, sans-serif",
        color: "#555",
      },
      ":focus": { color: "blue" },
      ":disabled": { cursor: "not-allowed" },
      valid: { color: "#3c763d" },
      invalid: { color: "#a94442" },
    };

    // Initialize Flex
    const flex = new Flex(captureContext);
    const microform = flex.microform({ styles: myStyles });
    const number = microform.createField("number", {
      placeholder: "Enter card number",
    });
    const securityCode = microform.createField("securityCode", {
      placeholder: "•••",
    });

    number.load("#number-container");
    securityCode.load("#securityCode-container");

    // Define the payment handler function
    const handlePayment = () => {
      const options = {
        expirationMonth: document.querySelector("#expMonth").value,
        expirationYear: document.querySelector("#expYear").value,
      };

      console.log(options);

      microform.createToken(options, function (err, token) {
        if (err) {
          console.error(err);
          document.getElementById("errors-output").textContent = err.message;

          if (err?.details?.length > 0) {
            const errorMessage = err.details
              .map((detailElement, index) => {
                if (detailElement.location === "number") {
                  document.getElementById(
                    "number-errors"
                  ).textContent = `${detailElement.message} in ${detailElement.location}`;
                } else if (detailElement.location === "securityCode") {
                  document.getElementById(
                    "securityCode-errors"
                  ).textContent = `${detailElement.message} in ${detailElement.location}`;
                } else {
                  document.getElementById(
                    "field-errors"
                  ).textContent = `${detailElement.message} in ${detailElement.location}`;
                }
                return `${detailElement.message} in ${detailElement.location}`;
              })
              .join("\n");
          }

          loadingIcon.style.display = "none";
          payButton.disabled = false;
        } else {
          onPaymentProcessed(token, formData, fedexSelected, orderStatus);
        }
      });
    };

    // Add event listener for pay-button
    payButton.addEventListener("click", handlePayment);

    // Cleanup event listener on unmount
    return () => {
      payButton.removeEventListener("click", handlePayment);
    };
  }, [
    contextToken,
    formData,
    fedexSelected,
    orderStatus,
    paymentTransactionRes,
  ]); // Dependencies

  return (
    <div className="container">
      <div id="errors-output" style={{ color: "red" }} role="alert"></div>
      <div className="form-group card_number_main">
        <label id="cardNumber-label" className="font-semibold">Card Number</label>
        <div id="number-container" className="form-control"></div>
        <div id="number-errors" style={{ color: "red" }} role="alert"></div>
      </div>

      <div className="row mt-2">
        <div className="col-md-4 security_number_main">
          <label htmlFor="securityCode-container" className="font-semibold">Security Code</label>
          <div id="securityCode-container" className="form-control"></div>
          <div
            id="securityCode-errors"
            style={{ color: "red" }}
            role="alert"
          ></div>
        </div>
        <div className="form-group col-md-4 expiry_select_main">
          <label htmlFor="expMonth" className="font-semibold">Expiry Month</label>
          <select id="expMonth" className="form-select">
            {[...Array(12).keys()].map((m) => (
              <option key={m + 1} value={`0${m + 1}`.slice(-2)}>
                {`0${m + 1}`.slice(-2)}
              </option>
            ))}
          </select>
        </div>
        <div className="form-group col-md-4 expiry_select_main">
          <label htmlFor="expYear" className="font-semibold">Expiry Year</label>
          <select id="expYear" className="form-select">
            {[...Array(10).keys()].map((y) => (
              <option key={y + 2024} value={y + 2024}>
                {y + 2024}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div id="field-errors" style={{ color: "red" }} role="alert"></div>
      <div style={{display: "flex", justifyContent: "center", marginTop: "20px"}}>
      <ReCAPTCHA
        sitekey="6Lf3s3AqAAAAAMN3xPBY5IXrhKoHUO4gnXb220L_"
        onChange={(token)=> {
          localStorage.setItem('CaptchaToken', token);
          setCaptchaToken(token);
        }}
      />
      </div>
      <div
        style={{ display: "flex", justifyContent: "center", marginTop: "20px" }}
      >
        <button
          type="button"
          id="pay-button"
          disabled={!captchaToken}
          className="w-full h-[50px] black-btn flex justify-center items-center bank_of_america_btn_main"
        >
          Place Now
        </button>
        <img
          id="loadingIcon"
          height="30px"
          width="30px"
          style={{ marginLeft: "10px", display: "none" }}
          src={`${process.env.PUBLIC_URL}/assets/images/loading.gif`}
        />
      </div>
      <input type="hidden" id="flexresponse" name="flexresponse" />
    </div>
  );
};

export default BankOfAmerica;