import React, { useState } from 'react';

const ImageWithFallback = ({ src, fallbackSrc, alt, ...props }) => {
    const [currentSrc, setCurrentSrc] = useState(src);

    const handleError = () => {
        if (fallbackSrc && currentSrc !== fallbackSrc) {
            setCurrentSrc(fallbackSrc);
        }
    };

    return (
        <img
            src={currentSrc}
            alt={alt}
            onError={handleError}
            {...props}
        />
    );
};

export default ImageWithFallback;