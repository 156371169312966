import {getProductFilters, getProducts} from "../../actions/productAction";
import {getHomeMainCategoryDetail, getHomeManufacturerListing} from "../../actions/homeAction";
import {connect} from "react-redux";

const ChildCategoryView = (props) => {
  const { categoryTitle,className,children,seeMoreUrl,categoryData} = props;
  return (
      Array.isArray(categoryData?.data?.children) && categoryData.data.children.length > 0 && (
          <div className={`section-wrapper w-full ${className || ""}`}>
            <div className="container-x mx-auto">
              <div className="section-title flex justify-between items-center mb-5">
                <div>
                  <h1 className="sm:text-3xl text-xl font-600 text-qblacktext leading-none">
                    {categoryTitle}
                  </h1>
                </div>
                <div>
                  <a href={seeMoreUrl}>
                    <div className="flex space-x-2 items-center">
                      {/* Add your "View More" content here */}
                    </div>
                  </a>
                </div>
              </div>
              <div className="section-content">{children && children}</div>
            </div>
          </div>
      )
  );
}
const mapStateToProps = state => ({
});

const mapDispatchToProps = dispatch => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(ChildCategoryView);
