import Star from "../Helpers/icons/Star";
import React, { useEffect, useState } from "react";
import ReviewImageModel from "../Auth/Profile/tabs/ReviewImageModel";
import ThinPeople from "../Helpers/icons/ThinPeople";
import {FaCircleCheck} from "react-icons/fa6";

export default function Reviews({ comments, data }) {
  const [totalReview, setTotalReview] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [currentImage, setCurrentImage] = useState('');

  useEffect(() => {
    if (data && data.length > 0) {
      let reviews = 0;
      let ratings = 0;

      data.forEach((value) => {
        ratings += value.product_rating;
        reviews += 1;
      });

      let averageRating = Math.round(ratings / reviews);
      setTotalReview(averageRating);
    }
  }, [data]);

  const handleCloseModal = () => setShowModal(false);
  const handleOpenModal = (imageUrl) => {
    setCurrentImage(imageUrl);
    setShowModal(true);
  };

  const calculateDaysAgo = (created_at) => {
    const createdDate = new Date(created_at);
    const currentDate = new Date();
    const timeDifference = currentDate - createdDate;
    const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
    return daysDifference;
  };

  return (
      <>
        <ReviewImageModel show={showModal} onClose={handleCloseModal}>
          <img src={currentImage} alt="popup" />
        </ReviewImageModel>
        <div className="review-wrapper w-full">
          <div className="w-full reviews mb-[60px]">
            <div className="reviewblock">
              <h6>{totalReview}<span>/5</span></h6>
              <div className="flex space-x-1 items-center stars">
                {totalReview === 0 ? (
                    <Star key="0" w="33" h="30" />
                ) : (
                    Array.from({ length: totalReview }).map((_, index) => (
                        <Star key={index} w="33" h="30" />
                    ))
                )}
              </div>
            </div>
            {data && data.length > 0 && data.map((comment) => (
                <div key={comment.id} className="comment-item bg-white px-10 pt-3 mb-2.5">
                  <div className="comment-author mb-3">
                    <div className="flex items-center">
                      <ThinPeople size={19} />
                      {comment.show_username ? (
                          <h1 className="itemhead">{comment.website_order.website_customer_firstname+" "+comment.website_order.website_customer_lastname}</h1>
                        ) : (
                          <h1 className="itemhead">Anonymous</h1>
                      )}
                      <FaCircleCheck />
                      <p className="second">Verified</p>

                    </div>
                  </div>
                  <div className="comment-author mb-3">
                    <div className="flex items-center space-x-2 justify-between">
                      <div className="">
                        <p>Product Rating</p>
                        <div className="flex">
                          {Array.from(Array(comment.product_rating), (_, index) => (
                              <span key={`product-${comment.id}-${index}`}>
                          <Star />
                        </span>
                          ))}
                        </div>
                      </div>
                      <div className="">
                        <p>Seller Rating</p>
                        <div className="flex">
                          {Array.from(Array(comment.seller_rating), (_, index) => (
                              <span key={`seller-${comment.id}-${index}`}>
                          <Star />
                        </span>
                          ))}
                        </div>
                      </div>
                      <div className="">
                        <p>Delivery Rating</p>
                        <div className="flex">
                          {Array.from(Array(comment.delivery_rating), (_, index) => (
                              <span key={`delivery-${comment.id}-${index}`}>
                          <Star />
                        </span>
                          ))}
                        </div>
                      </div>
                      <span className="text-[13px] font-normal text-qblack mt-1 inline-block">
                    {calculateDaysAgo(comment.created_at)} Days
                  </span>
                    </div>
                  </div>
                  <div className="comment mb-[30px]">
                    <p className="text-[15px] text-qgray leading-7 text-normal">
                      {comment.remarks}
                    </p>
                    <div className="reviewImg">
                      {comment?.media.map((img, index) => (
                          <img
                              key={`media-${comment.id}-${index}`}
                              src={img.original_url}
                              alt=""
                              className="w-full h-full object-cover"
                              onClick={() => handleOpenModal(img.original_url)}
                          />
                      ))}
                    </div>
                  </div>
                  <hr />
                </div>
            ))}
          </div>
        </div>
      </>
  );
}
