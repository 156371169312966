import { Link } from "react-router-dom";
import ImageWithFallback from "../../ImageWithFallback";

export default function ProductCardRowStyleTwo({ className, datas }) {

  let imageUrl = '';
  let thumbnailImage = '';
  if (datas.website_category_media !== undefined && datas.website_category_media.length > 0) {
    if (datas.website_category_media[0].thumbnail !== undefined && datas.website_category_media[0].thumbnail !== null && datas.website_category_media[0].thumbnail !== "") {
      thumbnailImage = datas.website_category_media[0].thumbnail;
    } else {
      imageUrl = datas.website_category_media[0].original_url;
    }
  }


  return (
    <div
      data-aos="fade-up"
      className={`product-card-row-two w-full  ${className || ""}`}
    >
      <div className="w-full h-[130px] bg-white border border-primarygray px-5 ">
        <div className="w-full h-full flex space-x-5 justify-center items-center">
          <div className="w-[75px] h-[75px]">
            {/*<img*/}
            {/*  src={`${imageUrl}`}*/}
            {/*  alt="Featured Category"*/}
            {/*  className="w-full h-full object-cover"*/}
            {/*  loading="lazy"*/}
            {/*/>*/}
            <ImageWithFallback
                src={thumbnailImage}
                fallbackSrc={imageUrl}
                alt="Featured Category"
                className="w-full h-full object-cover"
                loading="lazy"
            />
          </div>
          <div className="flex-1 h-full flex flex-col justify-center">
            <Link to={`category/${datas.title}`} className="title-link">
              <p className="title mb-2 sm:text-[15px] text-[13px] font-600 text-qblack leading-[24px] line-clamp-1 hover:text-blue-600 " style={{ textAlign: 'left' }}>
                {datas.title}
              </p>
            </Link>
            <ul className="price">
              {datas.children &&
                datas.children.map((data) => (
                    <li>
                      <Link key={data.id} to={`category/${data.url}`} className="main-price font-400 text-[14px] ">
                        {data.title}
                      </Link>
                    </li>
                ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}
