import React from 'react';
import './ReviewImageModel.css';

const CustomModal = ({ show, onClose, children }) => {
    if (!show) {
        return null;
    }

    const handleOverlayClick = (e) => {
        if (e.target === e.currentTarget) {
            onClose();
        }
    };

    return (
        <div className="custom-modal-overlay" onClick={handleOverlayClick}>
            <div className="custom-modal">
                <button className="custom-modal-close" onClick={onClose}>
                    &times;
                </button>
                <div className="custom-modal-content">
                    {children}
                </div>
            </div>
        </div>
    );
};

export default CustomModal;
