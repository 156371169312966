import React, { useEffect, useState } from "react";
import InputCom from "../../Helpers/InputCom";
import Layout from "../../Partials/Layout";
import Thumbnail from "./Thumbnail";
import {
  city,
  countries,
  fedex,
  orderNow,
  resetOrder,
  states,
  websitepaymentmethods,
  websiteshippingmethods,
} from "../../../actions/commonAction";
import { connect } from "react-redux";
import { signup } from "../../../actions/customerAction";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { toast } from "react-toastify"; // Import eye icons
import { Link } from "react-router-dom";
import PageTitle from "../../Helpers/PageTitle";

const Signup = (props) => {
  const [checked, setValue] = useState(false);
  const [shippingCountries, setShippingCountries] = useState([]);
  const [shippingCities, setShippingCities] = useState([]);
  const [shippingStates, setShippingStates] = useState([]);
  const [isShippingManual, setIsShippingManual] = useState(false);
  const [shippingCountryName, setShippingCountryName] =
    useState("United States");
  const [shippingStateName, setShippingStateName] = useState("");
  const [shippingCityName, setShippingCityName] = useState("");
  const [isChecked, setIsChecked] = useState(false);

  const [showPassword, setShowPassword] = useState(false);

  // Function to toggle password visibility
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const [formData, setFormData] = useState({
    customer_firstname: "",
    customer_lastname: "",
    customer_email: "",
    customer_mobile: "",
    // customer_shipping_address1: "",
    customer_shipping_state: "US",
    customer_shipping_city: "",
    customer_shipping_postcode: "",
    customer_shipping_country: "US",
    customer_password: "",
    customer_confirm_password: "",
    company: "",
  });
  const resetForm = () => {
    setFormData({
      customer_firstname: "",
      customer_lastname: "",
      customer_email: "",
      customer_mobile: "",
      // customer_shipping_address1: "",
      customer_shipping_state: "US",
      customer_shipping_city: "",
      customer_shipping_postcode: "",
      customer_shipping_country: "US",
      customer_password: "",
      customer_confirm_password: "",
      company: "",
    });
  };

  const [formErrorData, setFormErrorData] = useState({
    customer_firstname_error: "",
    customer_lastname_error: "",
    customer_mobile_error: "",
    customer_email_error: "",
    // customer_shipping_address1_error: "",
    customer_shipping_state_error: "",
    customer_shipping_city_error: "",
    customer_shipping_postcode_error: "",
    customer_shipping_country_error: "",
    customer_password_error: "",
    customer_confirm_password_error: "",
    company_error: "",
  });

  const dropDownhandleChange = (e) => {
    const { name, type, checked, value, options } = e.target;
    // If the type is checkbox, set the value to the boolean checked state
    const newValue = type === "checkbox" ? checked : value;

    const dropDownName =
      options[e.target.selectedIndex]?.getAttribute("data-dropdownname");

    if (name === "customer_shipping_country") {
      setShippingCountryName(dropDownName);
    } else if (name === "customer_shipping_city") {
      setShippingCityName(dropDownName);
    } else if (name === "customer_shipping_state") {
      setShippingStateName(dropDownName);
    }

    setFormData((prevData) => ({
      ...prevData,
      [name]: newValue,
    }));
  };

  useEffect(() => {
    const fetchData = async () => {
      if (formData.customer_shipping_country) {
        await Promise.all([props.states(formData.customer_shipping_country)]);
      }
    };

    fetchData();
  }, [formData.customer_shipping_country]);

  useEffect(() => {
    const fetchData = async () => {
      if (formData.customer_shipping_country) {
        if (
          formData.customer_shipping_state !== "" &&
          formData.customer_shipping_state !== undefined
        ) {
          await Promise.all([
            props.city(
              formData.customer_shipping_country,
              formData.customer_shipping_state
            ),
          ]);
        }
      }
    };

    fetchData();
  }, [formData.customer_shipping_state]);

  const handleChange = (e) => {
    const { name, type, checked, value } = e.target;
    // If the type is checkbox, set the value to the boolean checked state
    const newValue = type === "checkbox" ? checked : value;

    setFormData((prevData) => ({
      ...prevData,
      [name]: newValue,
    }));
  };

  useEffect(() => {
    props.countries();
    props.states("US");
  }, []);

  useEffect(() => {
    const countriesData = props.country_res.data;
    // Map countries to objects with both name and iso2 properties
    const countriesWithIso2 = countriesData.map((country) => ({
      name: country.name,
      iso2: country.iso2,
    }));
    // Set the countries with iso2 values in state
    setShippingCountries(countriesWithIso2);
  }, [props.country_res]);

  useEffect(() => {
    const cityData = props.city_res.data;
    const stateData = props.states_res.data;

    if (stateData.length > 0) {
      const stateDataWithIso2 = stateData.map((city) => ({
        name: city.name,
        iso2: city.iso2,
      }));
      setShippingStates(stateDataWithIso2);
    }
    if (cityData.length > 0) {
      const cityDataWithIso2 = cityData.map((city) => ({
        name: city.name,
        iso2: city.name,
      }));
      setShippingCities(cityDataWithIso2);
    }
  }, [props.city_res, props.states_res]);

  const handleCheckboxChangeShipping = () => {
    setIsShippingManual(!isShippingManual);
  };

  const rememberMe = () => {
    setValue(!checked);
  };

  const handleSubmit = () => {
    const data = {
      firstname: formData.customer_firstname,
      lastname: formData.customer_lastname,
      mobile: formData.customer_mobile,
      email: formData.customer_email,
      password: formData.customer_password,
      password_confirmation: formData.customer_confirm_password,
      // customer_shipping_address1: formData.customer_shipping_address1,
      // shipping_city: shippingCityName,
      shipping_country: shippingCountryName,
      // shipping_postcode: formData.customer_shipping_postcode,
      // shipping_state: shippingStateName,
      // company: formData.company,
    };
    props.signup(data);
  };

  useEffect(() => {
    if (props.sign_res?.status === 201) {
      toast.success("Account created successfully!", {
        position: "top-right",
        autoClose: 3000, // Close the notification after 3000 milliseconds (3 seconds)
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
      resetForm();
    } else if (
      props.sign_res?.message !== "" &&
      props.sign_res?.message !== undefined
    ) {
      toast.error(props.sign_res?.message, {
        position: "top-right",
        autoClose: 3000, // Close the notification after 3000 milliseconds (3 seconds)
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
      setFormErrorData((prevData) => ({
        ...prevData,
        customer_firstname_error: props.sign_res.errors["firstname"]
          ? props.sign_res.errors["firstname"][0]
          : "",
        customer_lastname_error: props.sign_res.errors["lastname"]
          ? props.sign_res.errors["lastname"][0]
          : "",
        customer_email_error: props.sign_res.errors["email"]
          ? props.sign_res.errors["email"][0]
          : "",
        customer_mobile_error: props.sign_res.errors["mobile"]
          ? props.sign_res.errors["mobile"][0]
          : "",
        customer_password_error: props.sign_res.errors["password"]
          ? props.sign_res.errors["password"][0]
          : "",
        customer_confirm_password_error: props.sign_res.errors[
          "password_confirmation"
        ]
          ? props.sign_res.errors["password_confirmation"][0]
          : "",
        customer_shipping_country_error: props.sign_res.errors[
          "shipping_country"
        ]
          ? props.sign_res.errors["shipping_country"][0]
          : "",
        customer_shipping_state_error: props.sign_res.errors["shipping_state"]
          ? props.sign_res.errors["shipping_state"][0]
          : "",
        customer_shipping_postcode_error: props.sign_res.errors[
          "shipping_postcode"
        ]
          ? props.sign_res.errors["shipping_postcode"][0]
          : "",
        customer_shipping_city_error: props.sign_res.errors["shipping_city"]
          ? props.sign_res.errors["shipping_city"][0]
          : "",
        company_error: props.sign_res.errors["company"]
          ? props.sign_res.errors["company"][0]
          : "",
      }));
    }
  }, [props.sign_res]);

  return (
    <Layout childrenClasses="pt-0 pb-0">
      <div className="login-page-wrapper w-full pb-10">
        <PageTitle
          title="Sign Up"
          // breadcrumb={[
          //   { name: "home", path: "/" },
          //   { name: "cart", path: "/cart" },
          // ]}
        />
        <div className="container-x mx-auto">
          {/* <Link to={"#"}>
            <img
              width="400"
              height="36"
              src={`${process.env.PUBLIC_URL}/assets/images/ETechBuy-Logo.svg`}
              alt="logo"
              style={{ margin: "0 auto", paddingBottom: 30 }}
            />
          </Link> */}
          {/* <h1 className="text-center mb-4 text-3xl font-semibold text-qblack">
            Sign up
          </h1> */}
          <div className="lg:flex items-center relative signupBox loginBox">
            <div className="lg:w-[572px] w-full signupRight  bg-white flex flex-col justify-center sm:p-10 p-4 border border-[#E0E0E0]">
              <form id={"registerForm"}>
                <div className="w-full">
                  {/* <div className="title-area flex flex-col justify-center items-center relative text-center mb-7">
                    <h1 className="text-[34px] font-bold leading-[74px] text-qblack">
                      Create Account
                    </h1>
                    <div className="shape -mt-6">
                      <svg
                          width="354"
                          height="30"
                          viewBox="0 0 354 30"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                            d="M1 28.8027C17.6508 20.3626 63.9476 8.17089 113.509 17.8802C166.729 28.3062 341.329 42.704 353 1"
                            stroke="#FFBB38"
                            strokeWidth="2"
                            strokeLinecap="round"
                        />
                      </svg>
                    </div>
                  </div> */}
                  <div className="input-area">
                    <div className="flex space-x-5 items-center mb-6">
                      <div className="w-1/2">
                        <label htmlFor="customer_firstname" className="font-bold mb-1">First Name*</label>
                        <input
                          id="customer_firstname"
                          name="customer_firstname"
                          placeholder="First Name"
                          value={formData.customer_firstname} // Uncomment this line
                          onChange={handleChange}
                          className="w-full h-[50px] border border-[#EDEDED] px-2"
                        />
                        <span className="text-danger">
                          {formErrorData.customer_firstname_error}
                        </span>
                      </div>
                      <div className="w-1/2">
                        <label htmlFor="customer_lastname" className="font-bold mb-1">Last Name*</label>
                        <input
                          id="customer_lastname"
                          name="customer_lastname"
                          placeholder="Last Name"
                          value={formData.customer_lastname} // Uncomment this line
                          onChange={handleChange}
                          className="w-full h-[50px] border border-[#EDEDED] px-2"
                        />
                        <span className="text-danger">
                          {formErrorData.customer_lastname_error}
                        </span>
                      </div>
                    </div>
                    <div className="flex space-x-5 items-center mb-6">
                      <div className="w-1/2">
                        <label htmlFor="customer_mobile" className="font-bold mb-1">Phone Number*</label>
                        <input
                          id="customer_mobile"
                          name="customer_mobile"
                          type="text"
                          placeholder="+1270 00000"
                          value={formData.customer_mobile} // Uncomment this line
                          onChange={handleChange}
                          className="w-full h-[50px] border border-[#EDEDED] px-2"
                        />
                        <span className="text-danger">
                          {formErrorData.customer_mobile_error}
                        </span>
                      </div>
                      <div className="w-1/2">
                        <label htmlFor="customer_email" className="font-bold mb-1">Email Address*</label>
                        <input
                          id="customer_email"
                          name="customer_email"
                          type="email"
                          placeholder="example@gmail.com"
                          value={formData.customer_email} // Uncomment this line
                          onChange={handleChange}
                          className="w-full h-[50px] border border-[#EDEDED] px-2"
                        />
                        <span className="text-danger">
                          {formErrorData.customer_email_error}
                        </span>
                      </div>
                    </div>

                    <div className="flex space-x-5 items-center mb-6">
                      <div className="w-1/2">
                        <label htmlFor="customer_password" className="font-bold mb-1">Password*</label>
                        <div className="relative">
                          <input
                            id="customer_password"
                            name="customer_password"
                            type={showPassword ? "text" : "password"}
                            placeholder="*******"
                            value={formData.customer_password}
                            onChange={handleChange}
                            className="w-full h-[50px] border border-[#EDEDED] px-2"
                          />
                          <button
                            type="button"
                            className="absolute inset-y-0 right-0 px-3 py-1"
                            onClick={togglePasswordVisibility}
                          >
                            {showPassword ? <FaEyeSlash /> : <FaEye />}{" "}
                            {/* Use eye icons */}
                          </button>
                        </div>
                        <span className="text-danger">
                          {formErrorData.customer_password_error}
                        </span>
                      </div>
                      <div className="w-1/2">
                        <label htmlFor="customer_confirm_password" className="font-bold mb-1">
                          Confirm Password*
                        </label>
                        <div className="relative">
                          <input
                            id="customer_confirm_password"
                            name="customer_confirm_password"
                            type={showPassword ? "text" : "password"}
                            placeholder="*******"
                            value={formData.customer_confirm_password}
                            onChange={handleChange}
                            className="w-full h-[50px] border border-[#EDEDED] px-2"
                          />
                          <button
                            type="button"
                            className="absolute inset-y-0 right-0 px-3 py-1"
                            onClick={togglePasswordVisibility}
                          >
                            {showPassword ? <FaEyeSlash /> : <FaEye />}{" "}
                            {/* Use eye icons */}
                          </button>
                        </div>
                        <span className="text-danger">
                          {formErrorData.customer_confirm_password_error}
                        </span>
                      </div>
                    </div>

                    <div className="flex space-x-5 items-center mb-6">
                      <div className="w-1/2">
                        <label htmlFor="Country" className="font-bold mb-1">Country*</label>
                        <div className="slectBorder flex justify-between items-center">
                          <select
                            className="text-[13px] text-qgraytwo h-[50px] w-full"
                            id="customer_shipping_country"
                            name="customer_shipping_country"
                            value={formData.customer_shipping_country || "US"} // Set default value to 'US'
                            onChange={dropDownhandleChange}
                            style={{ fontWeight: "bold", color: "#000" }}
                          >
                            <option value="" disabled>
                              Select a country
                            </option>
                            {shippingCountries.map((country, index) => (
                              <option
                                key={index}
                                data-dropdownname={country.name}
                                value={country.iso2}
                              >
                                {country.name}
                              </option>
                            ))}
                          </select>
                        </div>
                        <span className="text-danger">
                          {formErrorData.customer_shipping_country_error}
                        </span>
                      </div>

                      {/*<div className="w-1/2">*/}
                      {/*  <label htmlFor="shipping_state">State*</label>*/}
                      {/*  <div className="w-full  rounded  slectBorder flex justify-between items-center">*/}
                      {/*    <select*/}
                      {/*        className="text-[13px]  rounded text-qgraytwo w-full h-[50px] px-2"*/}
                      {/*        onChange={dropDownhandleChange}*/}
                      {/*        id="customer_shipping_state"*/}
                      {/*        name="customer_shipping_state"*/}
                      {/*        value={formData.customer_shipping_state}*/}
                      {/*        style={{ fontWeight:'bold', color:'#000' }}*/}
                      {/*    >*/}
                      {/*      <option value="" >*/}
                      {/*        Select a state*/}
                      {/*      </option>*/}
                      {/*      {shippingStates.map((state, index) => (*/}
                      {/*          <option key={index} data-dropdownname={state.name} value={state.iso2}>*/}
                      {/*            {state.name}*/}
                      {/*          </option>*/}
                      {/*      ))}*/}
                      {/*    </select>*/}
                      {/*  </div>*/}
                      {/*</div>*/}
                    </div>
                    <div className="flex space-x-5 items-center mb-6 w-full">
                      <div
                        style={{
                          display: "flex",
                          // alignItems: "center",
                          // justifyContent: "flex-start",
                        }}
                      >
                        <input
                          type="checkbox"
                          id="c"
                          style={{ width: "16px" }}
                          onChange={(e) => setIsChecked(e.target.checked)}
                        />
                        <label
                          htmlFor="c"
                          style={{ marginLeft: 10, width: "auto" }}
                        >
                          I have read and agree to ETechBuy.com
                          <Link
                            to={"/terms-condition"}
                            className="font-semibold ml-1
                        "
                          >
                            Terms and Conditions
                          </Link>
                        </label>
                      </div>
                    </div>

                    <div className="signin-area mb-3">
                      <div className="flex justify-center">
                        <button
                          type="button"
                          onClick={handleSubmit}
                          disabled={!isChecked}
                          className={`text-sm w-full h-[50px] font-semibold flex justify-center items-center ${
                            isChecked ? "black-btn regBtn hover_main" : "regBtnGray"
                          }`}
                        >
                          <span>Register Now</span>
                        </button>
                      </div>
                    </div>

                    {
                      <div className="signup-area flex justify-center">
                        <p className="text-base text-qblack font-normal">
                          Already have an Account?
                          <Link to="/login" className="ml-2 text-qblack font-bold">
                            Log In
                          </Link>
                        </p>
                      </div>
                    }
                  </div>
                </div>
              </form>
            </div>
            <div className="flex-1 lg:flex hidden  xl:justify-center">
              <div
                className=" xl:-right-20 -right-[138px] signupLeft"
                style={{
                  top: "calc(50% - 258px)",
                  backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/Background-Image.jpg)`,
                }}
              >
                {/* <div className="signupContent">
                  <div className="label1">

                  </div>
                </div> */}
                <div className="paragraph-container one">
                  <div className="label1">
                    <img
                      width="80"
                      height="80"
                      src={`${process.env.PUBLIC_URL}/assets/images/Best-Price.png`}
                      alt="logo"
                      style={{ marginRight: "10px" }}
                    />
                    <h6>Shop Top-Rated Products at Unbeatable Prices!</h6>
                  </div>
                  <div className="label1">
                    <img
                      width="80"
                      height="80"
                      src={`${process.env.PUBLIC_URL}/assets/images/Express-Delivery.png`}
                      alt="logo"
                      style={{ marginRight: "10px" }}
                    />
                    <h6>Fast and Free Shipping Within a Day (US only).</h6>
                  </div>
                  <div className="label1">
                    <img
                      width="80"
                      height="80"
                      src={`${process.env.PUBLIC_URL}/assets/images/Timely-Return.png`}
                      alt="logo"
                      style={{ marginRight: "10px" }}
                    />
                    <h6>Hassle-Free Refunds and Returns.</h6>
                  </div>
                  <div className="label1">
                    <img
                      width="80"
                      height="80"
                      src={`${process.env.PUBLIC_URL}/assets/images/Secure-Payment.png`}
                      alt="logo"
                      style={{ marginRight: "10px" }}
                    />
                    <h6>Safe and Easy Payment Options.</h6>
                  </div>
                  {/* <div className="table-container">
                    <table>
                      <tr>
                        <td name="tableCell">
                          <img
                            width="100"
                            height="100"
                            src={`${process.env.PUBLIC_URL}/assets/images/Best-Price.png`}
                            alt="logo"
                          />{" "}
                        </td>
                        <td name="tableCell">
                          <b>Shop Top-Rated Products at Unbeatable Prices!</b>{" "}
                        </td>
                      </tr>
                      <tr>
                        <td name="tableCell">
                          <img
                            width="100"
                            height="100"
                            src={`${process.env.PUBLIC_URL}/assets/images/Express-Delivery.png`}
                            alt="logo"
                          />
                        </td>
                        <td name="tableCell">
                          <b>Fg Withiast and Free Shippinn a Day</b>{" "}
                          <p>(US only).</p>
                        </td>
                      </tr>
                      <tr>
                        <td name="tableCell">
                          <img
                            width="100"
                            height="100"
                            src={`${process.env.PUBLIC_URL}/assets/images/Timely-Return.png`}
                            alt="logo"
                          />
                        </td>
                        <td name="tableCell">
                          <b>Hassle-Free Refunds and Returns.</b>
                        </td>
                      </tr>
                      <tr>
                        <td name="tableCell">
                          <img
                            width="100"
                            height="100"
                            src={`${process.env.PUBLIC_URL}/assets/images/Secure-Payment.png`}
                            alt="logo"
                          />
                        </td>
                        <td name="tableCell">
                          <b>Safe and Easy Payment Options.</b>
                        </td>
                      </tr>
                    </table>
                  </div> */}
                </div>

                {/* <img
                  width="152"
                  height="36"
                  src={`${process.env.PUBLIC_URL}/assets/images/Background-Image.jpg`}
                  alt="logo"
                /> */}
                {/* <Thumbnail /> */}
              </div>
            </div>
          </div>
        </div>
        <div className="bottomBar">
          <p className="bottomBar-text">
            <Link to="/about">About Us</Link> |{" "}
            <Link to="/terms-condition">Terms & Conditions</Link> |{" "}
            <Link to="/privacy-policy">Privacy Policy</Link>
          </p>
        </div>
      </div>
    </Layout>
  );
};
const mapStateToProps = (state) => ({
  country_res: state.common.countryRes,
  states_res: state.common.statesRes,
  city_res: state.common.cityRes,
  sign_res: state.customer.signRes,
});

const mapDispatchToProps = (dispatch) => ({
  countries: () => dispatch(countries()),
  city: (country, state) => dispatch(city(country, state)),
  states: (country) => dispatch(states(country)),
  signup: (data) => dispatch(signup(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Signup);
