import React, { useState } from 'react';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import {Link} from "react-router-dom";

const MenuMain = ({ title, childTitle, subMenuItems }) => {
    const [openChildMenus, setOpenChildMenus] = useState({});
    const handleChildMenuClick = (childIndex) => {
        setOpenChildMenus((prevOpenChildMenus) => {
            const newOpenChildMenus = { ...prevOpenChildMenus };
            newOpenChildMenus[childIndex] = !newOpenChildMenus[childIndex];
            return newOpenChildMenus;
        });
    };

    return (
        <li className="category-item">
            <div
                className="flex justify-between items-center px-5 h-12 bg-white hover:bg-qyellow cursor-pointer"
                onClick={() => handleChildMenuClick(title)}
            >
                <div className="flex items-center space-x-6">
                    <span className="text-sm font-600 parentMenu">{title}</span>
                </div>
                <div>
          <span>
            {openChildMenus[title] ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
          </span>
                </div>
            </div>

            {/* Child Menu */}
            {openChildMenus[title] && (
                <ul className="child-menu">
                    {childTitle &&
                    childTitle.map((child, index) => (
                        <li
                            key={index}
                            className="px-8 ml-8 bg-white hover:bg-qyellow cursor-pointer"
                        >
                            <div
                                className="flex items-center space-x-6"
                                onClick={() => handleChildMenuClick(child.title)}
                                style={{
                                    cursor: 'pointer',
                                    display: 'flex',
                                    alignItems: 'center',
                                }}
                            >
                                <span className="text-sm font-400 childMenu">{child.title}</span>
                                <span>
                    {openChildMenus[child.title] ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                  </span>
                            </div>

                            {/* Sub-menu within Child Menu */}
                            {openChildMenus[child.title] && (
                                <ul className="sub-menu">
                                    {child.subMenuItems &&
                                    child.subMenuItems.map((item, subIndex) => (
                                        <li key={subIndex} className="childsMenu">
                                            <Link to={`/categories/${item.url}`}>
                                                {item.title}
                                            </Link>

                                        </li>
                                    ))}
                                </ul>
                            )}
                        </li>
                    ))}
                </ul>
            )}
        </li>
    );
};

export default MenuMain;
