import React, { useState, useEffect } from "react";
import ProductCardStyleOne from "./Cards/ProductCardStyleOne";
import DataIteration from "./DataIteration";
import ViewMoreTitle from "./ViewMoreTitle";

export default function SectionStyleThree({
                                              className,
                                              sectionTitle,
                                              seeMoreUrl,
                                              products = [],
                                              type,
                                          }) {
    const [isMobile, setIsMobile] = useState(false);
    const [showCount, setShowCount] = useState(4); // Initially show 3 products on mobile

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768); // Adjust breakpoint as needed
        };

        // Initial check on component mount
        handleResize();

        // Listen for window resize events
        window.addEventListener("resize", handleResize);

        // Clean up
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    // Function to handle loading more products
    const handleLoadMore = () => {
        setShowCount(showCount + 4); // Increase showCount by 3
    };

    return (
        <div className={`section-style-one ${className || ""}`}>
            <ViewMoreTitle categoryTitle={sectionTitle} seeMoreUrl={seeMoreUrl}>
                <div className="products-section w-full">
                    <div className="grid xl:grid-cols-4 lg:grid-cols-3 sm:grid-cols-2 grid-cols-1 xl:gap-[30px] gap-2">
                        <DataIteration
                            datas={products}
                            startLength={0}
                            endLength={isMobile ? showCount : products.length}
                        >
                            {({ datas }) => (
                                <div data-aos="fade-up" key={datas.id} className="item">
                                    <ProductCardStyleOne type={type} datas={datas} />
                                </div>
                            )}
                        </DataIteration>
                    </div>
                    {/* Center-align "See More" button */}
                    {isMobile && showCount < products.length && (
                        <div className="flex justify-center mt-4">
                            <button
                                className="w-[93px] h-full text-sm font-600 btnSearchs search-btn"
                                onClick={handleLoadMore}
                            >
                                See More
                            </button>
                        </div>
                    )}
                </div>
            </ViewMoreTitle>
        </div>
    );
}
