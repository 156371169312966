import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getTest } from '../../actions/commonAction';
import {getFeaturedcategories, getHomeBanner, getHomeTopManufacture} from '../../actions/homeAction';
import ManufactureProducts from './ManufactureProducts';
import SellerCategoriesProducts from './SellerCategoriesProducts';
import ProductsAds from './ProductsAds';
import SectionStyleFour from '../Helpers/SectionStyleFour';
import BrandSection from './BrandSection';
import Layout from '../Partials/Layout';
import Banner from './Banner';
import { Helmet } from 'react-helmet-async';

class Home extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loadManufactureProducts: false,
            loadProductsAds: false,
            loadSellerCategoriesProducts: false,
            loadFeaturedCategories: false,
            loadBrandSection: false,
            isTimerDone: window.innerWidth < 768 ? false : true,
        };
    }

    componentDidMount() {
        this.props.getHomeBanner();

        if (window.innerWidth < 768) { //checking if it is as mobile device
            this.props.getFeaturedcategories();
            setTimeout(() => {
                this.setState({ isTimerDone: true })
            }, 5000);
        } else {
            setTimeout(() => {
                this.props.getHomeTopManufacture();
                this.props.getFeaturedcategories();
            }, 3000);
        }
        // window.addEventListener('scroll', this.handleScroll);
    }

    // componentWillUnmount() {
    //     window.removeEventListener('scroll', this.handleScroll);
    // }

    // handleScroll = () => {
    //     if (window.scrollY > 500) {
    //         this.setState({ loadManufactureProducts: true });
    //     }
    //     if (window.scrollY > 1000) {
    //         this.setState({ loadProductsAds: true });
    //     }
    //     if (window.scrollY > 1500) {
    //         this.setState({ loadSellerCategoriesProducts: true });
    //     }
    //     if (window.scrollY > 2000) {
    //         this.setState({ loadFeaturedCategories: true });
    //     }
    //     if (window.scrollY > 2500) {
    //         this.setState({ loadBrandSection: true });
    //     }
    // };

    render() {
        const { loadManufactureProducts, loadProductsAds, loadSellerCategoriesProducts, loadFeaturedCategories, loadBrandSection } = this.state;
        const { home_banners, featured_categories_res } = this.props;

        const topBanners = home_banners.data.filter(banner => banner.type === 'Slider Banner');
        const rightLeftBanners = home_banners.data
            .filter(banner => banner.type === 'Left Banner' || banner.type === 'Right Banner')
            .map(banner => ({
                imageUrl: (banner.image && banner.image.length > 0) ? banner.image[0].original_url : '',
                thumbnailImageUrl: (banner.image && banner.image.length > 0) ? banner.image[0].thumbnail : '',
                button_url: banner.button_url // Assuming productLink is the parameter you want to extract
            }));

        return (
            <>
                <Helmet>
                    <script type="application/ld+json">
                        {JSON.stringify({
                                "@context": "https://schema.org",
                                "@type": "Organization",
                                "url": "https://etechbuy.com/",
                                "name": "Maximize Performance with essential Computer Components | ETechBuy.com",
                                "logo": "https://etechbuy.com/assets/images/logo.svg",
                                "description": "Discover premium-quality computer components to enhance speed, power, and efficiency, ensuring an upgraded computing experience like never before at ETechBuy.com",
                                "sameAs": [
                                    "https://www.facebook.com/etechbuy.official",
                                    "https://twitter.com/_ETechBuy",
                                    "https://www.youtube.com/@ETechBuy"
                                ],
                                "contactPoint": {
                                    "@type": "ContactPoint",
                                    "telephone": "(888) 719-5848",
                                    "contactType": "sales"
                                },
                                "address": {
                                    "@type": "PostalAddress",
                                    "postalCode": "93550",
                                    "addressRegion": "California",
                                    "addressLocality": "Palmdale",
                                    "streetAddress": "37925 6th St E #301 Palmdale, CA 93550",
                                    "addressCountry": {
                                        "@type": "Country",
                                        "name": "United States"
                                    }
                                }
                            }
                        )}
                    </script>
                </Helmet>

                <div className={this.state.isTimerDone ? "" : "d-none"}>
                <Layout childrenClasses="pt-0">
                    <Banner className="banner-wrapper xl:mb-[40px] lg:mb-[50px] md:mb-[30px] sm:mb-[40px] banner_top_main" bannerData={topBanners} />
                    <ManufactureProducts productData={this.props.top_manufacturers} sectionTitle="Top Manufacturer Products" className="category-products mb-[60px]" />
                    <ProductsAds ads={rightLeftBanners} sectionHeight="sm:h-[295px] h-full" className="products-ads-section mb-[60px]" />
                    <SellerCategoriesProducts sectionTitle="Top Seller Categories Products" className="category-products mb-[60px]" />
                    <SectionStyleFour products={featured_categories_res.data} sectionTitle="Featured Categories" seeMoreUrl="/" className="category-products featured_category mb-[60px]" />
                    <BrandSection sectionTitle="Shop by Manufacturer" className="brand-section-wrapper mb-[60px]" />

                    {/*<SectionStyleThree*/}
                    {/*    products={products}*/}
                    {/*    sectionTitle="Featured Categories"*/}
                    {/*    seeMoreUrl="/all-products"*/}
                    {/*    className="new-products mb-[60px]"*/}
                    {/*/>*/}

                    {/*<SectionStyleOne*/}
                    {/*    products={products}*/}
                    {/*    brands={brands}*/}
                    {/*    categoryTitle="Mobile & Tablet"*/}
                    {/*    sectionTitle="Gamer World"*/}
                    {/*    seeMoreUrl="/all-products"*/}
                    {/*    className="category-products mb-[60px]"*/}
                    {/*/>*/}

                    {/*<CampaignCountDown*/}
                    {/*    className="mb-[60px]"*/}
                    {/*    lastDate="2023-10-04 4:00:00"*/}
                    {/*/>*/}

                    {/*<ViewMoreTitle*/}
                    {/*    className="top-selling-product mb-[60px]"*/}
                    {/*    seeMoreUrl="/all-products"*/}
                    {/*    categoryTitle="Top Selling Products"*/}
                    {/*>*/}
                    {/*  <SectionStyleTwo products={products.slice(3, products.length)}/>*/}
                    {/*</ViewMoreTitle>*/}
                    {/*<ViewMoreTitle*/}
                    {/*    className="best-sallers-section mb-[60px]"*/}
                    {/*    seeMoreUrl="/sallers"*/}
                    {/*    categoryTitle="Best Saller"*/}
                    {/*>*/}
                    {/*  <BestSellers/>*/}
                    {/*</ViewMoreTitle>*/}

                    {/*<SectionStyleOne*/}
                    {/*    categoryBackground={`${process.env.PUBLIC_URL}/assets/images/section-category-2.jpg`}*/}
                    {/*    products={products.slice(4, products.length)}*/}
                    {/*    brands={brands}*/}
                    {/*    categoryTitle="Electronics"*/}
                    {/*    sectionTitle="Popular Sales"*/}
                    {/*    seeMoreUrl="/all-products"*/}
                    {/*    className="category-products mb-[60px]"*/}
                    {/*/>*/}
                    {/*<ProductsAds*/}
                    {/*    ads={[`${process.env.PUBLIC_URL}/assets/images/ads-3.png`]}*/}
                    {/*    className="products-ads-section mb-[60px]"*/}
                    {/*/>*/}
                    {/*<ProductsAds*/}
                    {/*    sectionHeight="164"*/}
                    {/*    ads={[`${process.env.PUBLIC_URL}/assets/images/ads-4.png`]}*/}
                    {/*    className="products-ads-section mb-[60px]"*/}
                    {/*/>*/}

                </Layout>
                </div>
            </>
        );
    }
}

const mapStateToProps = state => ({
    test: state.home.TEST,
    home_banners: state.home.bannerRes,
    featured_categories_res: state.home.featuredcategoriesRes,
    top_manufacturers: state.home.topManufacturers,
});

const mapDispatchToProps = dispatch => ({
    getTest: () => dispatch(getTest()),
    getHomeBanner: () => dispatch(getHomeBanner()),
    getFeaturedcategories: () => dispatch(getFeaturedcategories()),
    getHomeTopManufacture: () => dispatch(getHomeTopManufacture()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Home);

