import {Navigate, Route, Routes} from "react-router-dom";
import About from "./components/About";
import AllProductPage from "./components/AllProductPage";
import Login from "./components/Auth/Login/index";
import Profile from "./components/Auth/Profile";
import Signup from "./components/Auth/Signup";
import BecomeSaller from "./components/BecomeSaller";
import Blogs from "./components/Blogs";
import Blog from "./components/Blogs/Blog.jsx";
import CardPage from "./components/CartPage";
import CheakoutPage from "./components/CheakoutPage";
import Contact from "./components/Contact";
import Faq from "./components/Faq";
import FlashSale from "./components/FlashSale";
import FourZeroFour from "./components/FourZeroFour";
import Home from "./components/Home";
import HomeTwo from "./components/HomeTwo";
import PrivacyPolicy from "./components/PrivacyPolicy";
import ProductsCompaire from "./components/ProductsCompaire/index";
import SallerPage from "./components/SallerPage";
import Sallers from "./components/Sellers";
import SingleProductPage from "./components/SingleProductPage";
import TermsCondition from "./components/TermsCondition/index";
import TrackingOrder from "./components/TrackingOrder";
import Wishlist from "./components/Wishlist";
import HomeThree from "./components/HomeThree";
import HomeFour from "./components/HomeFour";
import HomeFive from "./components/HomeFive";
import ReturnPolicies from "./components/ReturnPolicies";
import Thankyou from "./components/CheakoutPage/thankyou";
import Reqforquote from "./components/Reqaquote";
import Orderprocess from "./components/OrderProcess";
import Warranty from "./components/Warranty";
import Taxexempt from "./components/TaxExempt";
import Services from "./components/Services";
import Shipping from "./components/Shipping";
import PaymentMethod from "./components/PaymentMethod"
import React from "react";
import ResetPassword from "./components/CheakoutPage/ResetPassword";
import WebPages from "./components/WebPages";
import FourZeroThree from "./components/FourZeroThree";
import Configurator from "./components/Configurator";
import Sitemap from "./components/Sitemap/index.jsx";

export default function Routers() {
    const userData = JSON.parse(localStorage.getItem('user_data'));
    const isAuthenticated = !!userData && !!userData.token;
  return (
    <Routes>
      <Route exact path="/" element={<Home />} />
      {/*<Route exact path="/home-two" element={<HomeTwo />} />*/}
      {/*<Route exact path="/home-three" element={<HomeThree />} />*/}
      {/*<Route exact path="/home-four" element={<HomeFour />} />*/}
      {/*<Route exact path="/home-five" element={<HomeFive />} />*/}
      {/*<Route exact path="/all-products" element={<AllProductPage />} />*/}
      <Route path="/manufacturer/*" element={<AllProductPage />} />
      <Route path="/category/*" element={<AllProductPage />} />
      <Route path="/categories/*" element={<AllProductPage />} />
      <Route path="/search/*" element={<AllProductPage />} />
      <Route exact path="/*" element={<SingleProductPage />} />
      {/*<Route exact path="/single-product" element={<SingleProductPage />} />*/}
      <Route exact path="/cart" element={<CardPage />} />
      <Route exact path="/checkout" element={<CheakoutPage />} />
      <Route exact path="/wishlist" el ement={<Wishlist />} />
      <Route exact path="/flash-sale" element={<FlashSale />} />
      <Route exact path="/saller-page" element={<SallerPage />} />
      <Route exact path="/products-compaire" element={<ProductsCompaire />} />
      <Route exact path="/sallers" element={<Sallers />} />
      <Route exact path="/about" element={<About />} />
      <Route exact path="/configurator" element={<Configurator />} />
      <Route exact path="/blogs" element={<Blogs />} />
      <Route exact path="/blog/*" element={<Blog />} />
      <Route exact path="/site-map.html" element={<Sitemap />} />
      <Route exact path="/tracking-order" element={<TrackingOrder />} />
      <Route exact path="/thank-you/*" element={<Thankyou />} />
      <Route exact path="/contact" element={<Contact />} />
      <Route exact path="/faq" element={<Faq />} />
      <Route exact path="/privacy-policy" element={<PrivacyPolicy />} />
      <Route exact path="/terms-condition" element={<TermsCondition />} />
      <Route exact path="/return-policies" element={<ReturnPolicies />} />
      <Route exact path="*" element={<FourZeroFour />} />
      <Route exact path="/reqforquote" element={<Reqforquote />} />
      <Route exact path="/order-process" element={<Orderprocess />} />
      <Route exact path="/warranty" element={<Warranty />} />
      <Route exact path="/taxexempt" element={<Taxexempt />} />
      <Route exact path="/services" element={<Services />} />
      <Route exact path="/shipping" element={<Shipping />} />
      <Route exact path="/404" element={<FourZeroFour />} />
      <Route exact path="/403" element={<FourZeroThree />} />
      <Route exact path="/payment-method" element={<PaymentMethod />} />
      <Route exact path={"reset-password"} element={<ResetPassword />} />
        <Route exact path="/web-page/*" element={<WebPages />} />

        <Route exact path="/login" element={isAuthenticated ? <Navigate to="/profile" replace /> : <Login /> }/>
        <Route exact path="/signup" element={isAuthenticated ? <Navigate to="/profile" replace /> :<Signup /> } />
        <Route exact path="/profile" element={isAuthenticated ? <Profile /> : <Navigate to="/login" replace />} />

        {/*<Route exact path="/become-saller" element={<BecomeSaller />} />*/}

    </Routes>
  );
}
