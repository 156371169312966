import React, { useState } from 'react';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import { Link } from 'react-router-dom';

const Menu = ({ title, link, childItems, closeSidebar }) => {
    const [openChildMenus, setOpenChildMenus] = useState(false);
    const [openSubMenu, setOpenSubMenu] = useState(null);

    const handleParentMenuClick = () => {
        setOpenChildMenus(!openChildMenus);
        setOpenSubMenu(null); // Close any open sub-menu when toggling parent menu
        // closeSidebar(); // Call the closeSidebar function
    };
    const closeSidebarMenu = () => {
        closeSidebar(); // Call the closeSidebar function
    };


    const handleChildItemClick = (index) => {
        setOpenSubMenu(openSubMenu === index ? null : index);
    };

    return (
        <li className="category-item">
            <div className="flex justify-between items-center px-5 h-12 bg-white hover:bg-qyellow cursor-pointer">
                <div className="flex items-center space-x-6">
          <span className="text-sm font-600 parentMenu">
            <Link to={`/category/${link}`} onClick={closeSidebarMenu}>
              {title}
            </Link>
          </span>
                </div>
                <div onClick={handleParentMenuClick}>
                    {childItems && childItems.length > 0 && (
                        <span className={openChildMenus ? 'MuiSvgIcon-root' : ''}>
              {openChildMenus ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
            </span>
                    )}
                </div>
            </div>

            {/* Child Menus */}
            {openChildMenus && childItems && childItems.length > 0 && (
                <ul className="child-menu">
                    {childItems.map((child, index) => (
                        <li key={index} className="px-8 ml-8 bg-white hover:bg-qyellow cursor-pointer">
                            <div
                                className="flex items-center space-x-6"
                                onClick={() => handleChildItemClick(index)}
                                style={{
                                    cursor: 'pointer',
                                    display: 'flex',
                                    alignItems: 'center',
                                }}
                            >
                                <Link to={`/category/${child.childItemLink}`} onClick={closeSidebarMenu}>
                                    {child.childTitle}
                                </Link>
                                {child.subMenuItems && child.subMenuItems.length > 0 && (
                                    <span className={openSubMenu === index ? 'MuiSvgIcon-root' : ''}>
                                        {openSubMenu === index ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                                      </span>
                                )}
                            </div>

                            {/* Sub-menu within Child Menu */}
                            {openSubMenu === index && child.subMenuItems && child.subMenuItems.length > 0 && (
                                <ul className="sub-menu">
                                    {child.subMenuItems.map((item, subIndex) => (
                                        <li key={subIndex} className="childsMenu">
                                            <Link to={`/category/${item.subchildItemLink}`} onClick={closeSidebarMenu}>
                                                {item.title}
                                            </Link>
                                        </li>
                                    ))}
                                </ul>
                            )}
                        </li>
                    ))}
                </ul>
            )}
        </li>
    );
};

export default Menu;
