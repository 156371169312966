import {commonConstants, customerConstants, orderConstants, productConstants} from "../../constants";

const initialState = {
    signRes: {
        data: {},
    },
    profileRes: {
        data: {},
    },
    loginRes: {},
    customerOrderRes: {
        data: [],
        links: {},
        meta: {},
        loading: true,
    },
    customerReviewRes:{
        data:{}
    },

    passwordRes:{},
    forgotRes: {
        success: false,
        message: '',
        data : {}
    },
    resetRes: {
        success: false,
        message: '',
        data : {}
    },
    postReviewRes:{},
    updateReviewRes:{},
    reviewByCategoryRes:{
        data: [],
        links: {},
        meta: {},
        loading: true,
    }
};

const customerReducer = (state = initialState, action) => {
    switch (action.type) {
        case customerConstants.GET_REVIEW_BY_CATEGORY:
            return {
                ...state,
                reviewByCategoryRes: action.payload,
            }
        case customerConstants.GET_REVIEW_BY_CATEGORY_ERROR:
            return {
                ...state,
                // Handle error state as needed
            }
        case customerConstants.POST_REVIEW:
            return {
                ...state,
                postReviewRes: action.payload,
            }
        case customerConstants.POST_REVIEW_ERROR:
            return {
                ...state,
                // Handle error state as needed
            }
        case customerConstants.POST_REVIEW_RESET:
            return {
                ...state,
                postReviewRes: {},

            }


        case customerConstants.UPDATE_REVIEW:
            return {
                ...state,
                updateReviewRes: action.payload,
            }
        case customerConstants.UPDATE_REVIEW_ERROR:
            return {
                ...state,
                // Handle error state as needed
            }
        case customerConstants.UPDATE_REVIEW_RESET:
            return {
                ...state,
                updateReviewRes: {},

            }
        case customerConstants.CUSTOMER_ORDER:
            return {
                ...state,
                customerOrderRes: action.payload,
            };
        case customerConstants.CUSTOMER_ORDER_ERROR:
            return {
                ...state,
                customerOrderRes: action.payload,
            };
        case customerConstants.CUSTOMER_REVIEW:
            return {
                ...state,
                customerReviewRes: action.payload,
            };
        case customerConstants.CUSTOMER_REVIEW_ERROR:
            return {
                ...state,
                customerReviewRes: action.payload,
            };
        case customerConstants.PASSWORD_CHANGE:
            return {
                ...state,
                passwordRes: action.payload,
            };
        case customerConstants.PASSWORD_CHANGE_ERROR:
            return {
                ...state,
                passwordRes: action.payload,
            };
        case customerConstants.SIGNUP:
            return {
                ...state,
                signRes: action.payload,
            };
        case customerConstants.SIGNUP_ERROR:
            return {
                ...state,
                signRes: action.payload,
            };

        case customerConstants.PROFILE_UPDATE:
            return {
                ...state,
                profileRes: action.payload,
            };
        case customerConstants.PROFILE_UPDATE_ERROR:
            return {
                ...state,
                profileRes: action.payload,
            };

        case customerConstants.LOGIN:
            return {
                ...state,
                loginRes: action.payload,
            };
        case customerConstants.LOGIN_ERROR:
            return {
                ...state,
                loginRes: action.payload,
            };

        case customerConstants.RESET_LOGIN:
            return {
                ...state,
                loginRes: {},
            };
        case customerConstants.FORGOT_PASSWORD:
            return {
                ...state,
                forgotRes: action.payload,
            };
        case customerConstants.FORGOT_PASSWORD_ERROR:
            return {
                ...state,
                forgotRes: action.payload,
            };

        case customerConstants.RESET_PASSWORD:
            return {
                ...state,
                restRes: action.payload,
            }
        case customerConstants.RESET_PASSWORD_ERROR:
            return {
                ...state,
                restRes: action.payload,
            }

        default:
            return state;
    }
};

export default customerReducer;
